import styled from 'styled-components';
import { Button, Row, Text } from 'theme';
import { MetaButton } from 'assets/meta-button';
import { MetaButtonHover } from 'assets/meta-button-hover';

export const MetaButtonBase = styled(Button)`
    position: relative;
    cursor: pointer;
`;

export const ButtonHover = styled(MetaButtonHover)`
    position: absolute;
    top: -10px;
    left: -10px;
`;

export const ButtonInitial = styled(MetaButton)`
    position: relative;
`;

export const TextWrapper = styled(Row)`
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
`;

export const Number = styled(Text)`
    position: absolute;
    bottom: -4px;
    left: 8%;
    letter-spacing: 0.1em;
`;
