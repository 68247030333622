import {
  color,
  font,
  space,
  shadow,
} from '@spatium/wallet-kit';

export const DefaultColors = {
  light: {
    background: [color(['#F5F5F5', '#FFFFFF', '#233045'])],
    foreground: [color(['#233045', '#FFFFFF', '#F5F5F5'])],
    primary: [color(['#04F8CA', '#FFFFFF', '#233045'])],

    secondary: [color(['#00B04E', '#FFFFFF', '#FFFFFF'])],
    success: [color(['#028D31', '#FFFFFF', '#FFFFFF'])],

    red: [color(['#FF0366', '#FFFFFF', '#FFFFFF'])],
    green: [color(['#04795B', '#FFFFFF', '#FFFFFF'])],
    redVeryLight: [color(['#FEE8E1', '#FFFFFF', '#FFFFFF'])],
    redLight: [color(['#F85F2F', '#FFFFFF', '#FFFFFF'])],
    tomato: [color(['#F82F2F', '#FFFFFF', '#FFFFFF'])],
    black: [color(['#262338', '#FFFFFF', '#FFFFFF'])],
    blackLight: [color(['#233045', '#1D204F', '#FFFFFF'])],
    white: [color(['#FFFFFF', '#FFFFFF', '#1D204F'])],
    gray: [color(['#7E7E7E', '#FFFFFF', '#1D204F'])],
    grayAlt: [color(['#6D6D6D', '#FFFFFF', '#1D204F'])],
    grayLight: [color(['#A2C0CD', '#FFFFFF', '#1D204F'])],
  },
  dark: {
    background: [color(['#233045', '#000000', '#FFFFFF'])],
    foreground: [color(['#FFFFFF', '#000000', '#233045'])],
    primary: [color(['#04F8CA', '#000000', '#233045'])],

    secondary: [color(['#00B04E', '#1D204F', '#FFFFFF'])],
    success: [color(['#028D31', '#1D204F', '#FFFFFF'])],

    red: [color(['#FF0366', '#1D204F', '#FFFFFF'])],
    green: [color(['#04795B', '#FFFFFF', '#FFFFFF'])],
    redVeryLight: [color(['#FEE8E1', '#FFFFFF', '#FFFFFF'])],
    redLight: [color(['#F85F2F', '#FFFFFF', '#FFFFFF'])],
    tomato: [color(['#F82F2F', '#FFFFFF', '#FFFFFF'])],
    black: [color(['#262338', '#1D204F', '#FFFFFF'])],
    blackLight: [color(['#233045', '#1D204F', '#FFFFFF'])],
    white: [color(['#FCFCFC', '#1D204F', '#1D204F'])],
    gray: [color(['#A0A3BD', '#1D204F', '#FFFFFF'])],
    grayAlt: [color(['#6D6D6D', '#FFFFFF', '#1D204F'])],
    grayLight: [color(['#A2C0CD', '#FFFFFF', '#1D204F'])],
  },
} as const;

export const DefaultFonts = {
  q1: font({
    family: 'Quicksand, sans-serif',
    size: space(28),
    lineHeight: space(35),
    weight: 700,
    style: 'normal',
  }),
  q2: font({
    family: 'Quicksand, sans-serif',
    size: space(20),
    lineHeight: space(25),
    weight: 700,
    style: 'normal',
  }),
  q3: font({
    family: 'Quicksand, sans-serif',
    size: space(16),
    lineHeight: space(20),
    weight: 700,
    style: 'normal',
  }),
  q4: font({
    family: 'Quicksand, sans-serif',
    size: space(54),
    lineHeight: space(68),
    weight: 600,
    style: 'normal',
  }),
  q5: font({
    family: 'Quicksand, sans-serif',
    size: space(24),
    lineHeight: space(30),
    weight: 500,
    style: 'normal',
  }),
  q6: font({
    family: 'Quicksand, sans-serif',
    size: space(16),
    lineHeight: space(20),
    weight: 500,
    style: 'normal',
  }),
  q7: font({
    family: 'Quicksand, sans-serif',
    size: space(12),
    lineHeight: space(15),
    weight: 500,
    style: 'normal',
  }),
  q8: font({
    family: 'Quicksand, sans-serif',
    size: space(14),
    lineHeight: space(21),
    weight: 400,
    style: 'normal',
  }),
  q9: font({
    family: 'Quicksand, sans-serif',
    size: space(18),
    lineHeight: space(26),
    weight: 500,
    style: 'normal',
  }),
  q10: font({
    family: 'Quicksand, sans-serif',
    size: space(15),
    lineHeight: space(24),
    weight: 500,
    style: 'normal',
  }),
} as const;

export const DefaultSpaces = {
  small: {
    unit: space(3),
    small: space(6),
    medium: space(12),
    large: space(18),
    side: space(16),
  },
  medium: {
    unit: space(5),
    small: space(10),
    medium: space(20),
    large: space(30),
    side: space(16),
  },
  large: {
    unit: space(7),
    small: space(15),
    medium: space(30),
    large: space(45),
    side: space(16),
  },
} as const;

export const DefaultShadows = {
  small: shadow({
    color: color(['black']),
    params: [[0, 3, 5, -1, 0.2], [0, 6, 10, 0, 0.14], [0, 1, 18, 0, 0.12]],
  }),
  medium: shadow({
    color: color(['black']),
    params: [[0, 7, 8, -4, 0.2], [0, 12, 17, 2, 0.14], [0, 5, 22, 4, 0.12]],
  }),
  large: shadow({
    color: color(['black']),
    params: [[0, 9, 11, -5, 0.2], [0, 18, 28, 2, 0.14], [0, 7, 34, 6, 0.12]],
  }),
} as const;

export const DefaultScales = {
  small: 1,
  medium: 1,
  large: 1,
} as const;

export const DefaultSizes = {
  small: 0,
  medium: 1,
  large: 2,
} as const;
