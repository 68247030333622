import { FC, memo, useState } from 'react';
import { ClassNameProps, useWindowDimensions } from '@spatium/wallet-kit';

import { theme } from 'theme';

import { FadeSwitchWrapper } from 'components/fade-switch-wrapper';
import { ButtonLoader } from 'components/button-loader';
import { ButtonDisable } from 'assets/button-disable';
import { ButtonMobile } from 'assets/button-mobile';
import ButtonInitial from 'assets/button-initial/button-initial.svg';

import { VerifyButtonBase, ButtonHover, Loader, TextWrapper, Number } from './verify-button.styles';

export type VerifyButtonProps = {
  loading?: boolean;
  number?: number;
  mobile?: boolean;
};

export const VerifyButton: FC<VerifyButtonProps & ClassNameProps> = memo(({ className, style, children, loading, number, mobile }) => {
  const { innerWidth } = useWindowDimensions();
  const [hover, setHover] = useState(false);

  return (
    <VerifyButtonBase
      className={className}
      style={style}
      disabled={loading}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {loading
        ? <ButtonDisable />
        : (!mobile || innerWidth > 540)
          ? <img src={ButtonInitial} alt='Button' />
          : <ButtonMobile />}

      {innerWidth > 768 &&
        <FadeSwitchWrapper transitionDuration={100} switchKey={hover && !loading}>
          {hover && !loading &&
            <ButtonHover />}
        </FadeSwitchWrapper>}

      <FadeSwitchWrapper switchKey={!!loading}>
        {loading &&
          <Loader>
            <ButtonLoader />
          </Loader>}
      </FadeSwitchWrapper>

      <TextWrapper>
        {children}
      </TextWrapper>

      {number &&
        <Number
          themeFont={theme.fonts.q4.override({ size: theme.space(12), lineHeight: theme.space(15) })}
          themeTextColor={theme.colors.grayAlt}>
          {number}
        </Number>}
    </VerifyButtonBase>)
});
