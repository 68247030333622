import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { isMobile } from 'react-device-detect';
import { useGeneratorCallback, useGeneratorEffect } from 't-hooks';
import { Task } from 't-tasks';
import { Connector, useAccount, useConnect, useDisconnect, useNetwork, useSwitchNetwork, useSigner } from 'wagmi';
import { screen, useOptionalRef } from '@spatium/wallet-kit';
import { Unika, IdentificationStatus, StatusData as UnikaStatusData } from '@unika/sdk';

import { hexToString } from 'lib/utils';
import { useShowSnack } from 'lib/snack';
import { ChainObject, chains } from 'lib/chains';

import { ButtonLoader } from 'components/button-loader';

import { MainProps } from './main.props';
import { MainView } from './main.view';

export type Status =
  'initial' |
  'connecting' |
  'connected' |
  'signing' |
  'sending' |
  'queued' |
  'assigned' |
  'claimed' |
  'signing-message' |
  'starting' |
  'timeout' |
  'verifying' |
  'verified';

export const Main = screen<MainProps>(() => {
  const showSnack = useShowSnack();

  const [requestPosition, setRequestPosition] = useState<number>();
  const [claimTimestamp, setClaimTimestamp] = useState<Date>();
  const [identificationTimestamp, setIdentificationTimestamp] = useState<Date>();

  const [status, setStatus] = useState<Status>('initial');
  const statusRef = useRef<Status>();

  const [infoModalRef, openInfoModal, closeInfoModal] = useOptionalRef();
  const [metamaskModalRef, openMetamaskModal, closeMetamaskModal] = useOptionalRef();
  const [errorModalRef, openErrorModal, closeErrorModal] = useOptionalRef();

  const { connect, error: connectError, pendingConnector } = useConnect();
  const { disconnect, error: disconnectError } = useDisconnect();
  const { address, isConnected } = useAccount({
    onConnect: ({ isReconnected }) => {
      !isReconnected && showSnack('wallet-connected');
    },
  });
  const { switchNetwork, error: switchError } = useSwitchNetwork({
    onSuccess(data) {
      setSelectedChain(chains.find((x) => x.id === data.id) ?? chains[0]);
    },
  });

  const { data: signer } = useSigner();
  const { chain: connectedChain } = useNetwork();

  const [selectedChain, setSelectedChain] = useState<ChainObject>(chains[0]);

  const onSelectChain = useCallback((selected: ChainObject) => {
    if (isConnected && switchNetwork) {
      switchNetwork(selected.id);
    } else {
      setSelectedChain(selected);
    }
  }, [isConnected, switchNetwork]);

  useEffect(() => {
    if (!connectError) {
      return;
    } else if ((connectError as any)?.code === 4001) {
      showSnack('operation-canceled');
    } else if (connectError.name === 'ConnectorNotFoundError' && pendingConnector?.id === 'metaMask') {
      !isMobile ? openMetamaskModal() : showSnack('metamask-not-installed');
    } else {
      console.error('connectError:', connectError);
      showSnack('unknown-error');
    }

    setStatus('initial');
  }, [connectError, pendingConnector, openMetamaskModal, showSnack]);

  useEffect(() => {
    if (!switchError) {
      return;
    } else {
      console.error('switchError:', switchError);
      showSnack('unknown-error');
    }
  }, [switchError, showSnack]);

  useEffect(() => {
    if (!disconnectError) {
      return;
    } else {
      console.error('disconnectError:', disconnectError);
      showSnack('unknown-error');
    }
  }, [disconnectError, showSnack]);

  useEffect(() => {
    console.log('status:', status);
    statusRef.current = status
  }, [status]);

  useGeneratorEffect(function* () {
    if (!signer || !address) {
      return;
    }

    setStatus('connecting');

    // debounce due to wagmi useSigner bug
    yield* Task.timeout(100).generator();

    try {
      yield* Task.promiseGenerator(Unika.init({
        signer,
        onIdentificationStatus: async (status: IdentificationStatus, data?: UnikaStatusData) => {
          switch (status) {
            case 'identification-queued':
              if (data?.position) {
                setRequestPosition(data.position);
                setStatus('queued');
                showSnack('placed-in-queue');
                /* } else {
                  console.error('identification-queued-data:', data);
                  showSnack('unknown-error'); */
              } break;
            case 'identification-position-updated':
              if (data?.position) {
                setRequestPosition(data.position);
              } break;
            case 'identification-assigned':
              setStatus('assigned'); break;
            case 'identification-claimed':
              if (data?.claimTimestamp) {
                setClaimTimestamp(new Date(data.claimTimestamp * 1000));
                setStatus('claimed');
                showSnack({ key: 'validator-assigned', duration: 10000 });
              } else {
                console.error('identification-claimed-data:', data);
                showSnack('unknown-error');
              } break;
            case 'identification-processing':
              setStatus('verifying'); break;
            case 'identification-canceled':
              setStatus('claimed');
              showSnack('operation-canceled'); break;
            case 'identification-context-switched':
              setStatus('claimed');
              showSnack({ key: 'context-switched', duration: 10000 }); break;
            case 'identification-success':
              if (data?.identificationTimestamp) {
                setIdentificationTimestamp(new Date(data.identificationTimestamp * 1000));
                setStatus('verified');
              } else {
                console.error('identification-success-data:', data);
                openErrorModal();
              } break;
            case 'identification-error':
              setStatus('claimed');
              showSnack('unknown-error'); break;
            case 'identification-timeout':
              setStatus('timeout'); break;
            default:
              console.log('unika-status:', status); break;
          }
        }
      }));

      const identificationTimestamp = yield* Task.promiseGenerator(Unika.getIdentificationTimestamp(address));
      console.log('identificationTimestamp:', identificationTimestamp);

      if (identificationTimestamp !== 0) {
        setIdentificationTimestamp(new Date(identificationTimestamp * 1000));
      }

      const { status, position, claimTimestamp } = yield* Task.promiseGenerator(Unika.getIdentificationRequestData(address));
      console.log('identification-status:', status);
      console.log('position:', position);
      console.log('claimTimestamp:', claimTimestamp);

      setRequestPosition(position);
      setClaimTimestamp(claimTimestamp ? new Date(claimTimestamp * 1000) : undefined);

      if (status === 'claimed') {
        setStatus('claimed');
      } else if (status === 'assigned') {
        setStatus('assigned');
      } else if (status === 'queued') {
        setStatus('queued');
      } else if (identificationTimestamp !== 0) {
        setStatus('verified');
      } else {
        setStatus('connected');
      }
    } catch (e) {
      console.error(e);
      showSnack('unknown-error');
    }
  }, [address, signer, showSnack]);

  const disconnectWallet = useCallback(() => {
    setStatus('initial');
    setRequestPosition(undefined);
    setClaimTimestamp(undefined);
    setIdentificationTimestamp(undefined);

    disconnect();
    Unika.deinit();
  }, [disconnect]);

  useEffect(() => {
    if (!connectedChain) {
      return;
    } else if (!chains.find((x) => x.id === connectedChain.id)) {
      disconnectWallet();
      showSnack('unsupported-chain');
    }
  }, [connectedChain, disconnectWallet, showSnack]);

  const connectWallet = useCallback(async (connector: Connector, chainId: number) => {
    try {
      setStatus('connecting');

      connect({
        connector,
        chainId,
      });
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        showSnack('operation-canceled');
      } else if (e?.message === 'metamask-not-installed') {
        openMetamaskModal();
      } else {
        console.error(e);
        showSnack('unknown-error');
      }

      disconnectWallet();
    }
  }, [connect, disconnectWallet, showSnack, openMetamaskModal]);

  const initializeIdentification = useGeneratorCallback(function* () {
    try {
      if (!address) {
        throw Error('wallet-not-connected');
      }

      setStatus('sending');

      yield* Task.promiseGenerator(Unika.sendIdentificationRequest());

      showSnack('tx-sent');

      const { status, position, claimTimestamp } = yield* Task.promiseGenerator(Unika.getIdentificationRequestData(address));
      console.log('position:', position);
      console.log('claimTimestamp:', claimTimestamp);

      setRequestPosition(position);
      setClaimTimestamp(claimTimestamp ? new Date(claimTimestamp * 1000) : undefined);

      if (status === 'claimed') {
        setStatus('claimed');
        showSnack({ key: 'validator-assigned', duration: 10000 });
      } else if (status === 'assigned') {
        setStatus('assigned');
      } else if (status === 'queued') {
        setStatus('queued');
        showSnack('placed-in-queue');
      }

      console.log('initialize-identification-success');
    } catch (e: any) {
      setStatus('connected');

      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        showSnack('operation-canceled');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
      } else {
        console.error(e);
        showSnack('unknown-error');
      }
    }
  }, [address, showSnack]);

  const customLoader = useMemo(() => {
    const loader = document.createElement('div');
    const staticElement = renderToStaticMarkup(<ButtonLoader size={40} />)
    loader.innerHTML = staticElement;

    return loader;
  }, []);

  const onIdentify = useCallback(async () => {
    if (!signer) {
      throw Error('wallet-not-connected');
    }

    setStatus('starting');

    try {
      await Unika.identifyClient({
        resourcesDirectory: 'facetec/resources',
        customLoader,
        cancelButtonImage: 'facetec/images/cancel.svg',
        retryScreenIdealImage: 'facetec/images/ideal.png',
        cameraPermissionsScreenImage: 'facetec/images/camera.png',
        textFontHeader: 'Quicksand;\nfont-weight: 500',
        textFontSubtext: 'Quicksand',
      });
    } catch (e: any) {
      setStatus('claimed');

      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        showSnack('operation-canceled');
      } else {
        console.error(e);
        showSnack('unknown-error');
      }
    }
  }, [signer, customLoader, showSnack]);

  const onTimeout = useCallback(() => {
    setStatus('timeout');
    showSnack('verification-timeout');
  }, [showSnack]);

  const onOpenInfoModal = useCallback(() => {
    openInfoModal()
  }, [openInfoModal]);

  const onCloseInfoModal = useCallback(() => {
    closeInfoModal();
  }, [closeInfoModal]);

  const onCloseMetamaskModal = useCallback(() => {
    closeMetamaskModal()
  }, [closeMetamaskModal]);

  const onErrorTryAgain = useCallback(() => {
    setStatus('connected');
    closeErrorModal();
  }, [closeErrorModal]);

  const onMetamaskTryAgain = useCallback(() => {
    closeMetamaskModal();
    window.location.reload();
  }, [closeMetamaskModal]);

  const onTryDemo = useCallback(() => {
    window.open(process.env.REACT_APP_DEMO_AIRDROP_URL);
  }, []);

  const onInstallMetamask = useCallback(() => {
    window.open('https://metamask.io/download');
  }, []);

  return (
    <MainView
      status={status}
      address={address}
      selectedChain={selectedChain}
      connectedChain={connectedChain}
      identificationTimestamp={identificationTimestamp}
      claimTimestamp={claimTimestamp}
      requestPosition={requestPosition}
      infoModalRef={infoModalRef}
      metamaskModalRef={metamaskModalRef}
      errorModalRef={errorModalRef}
      onSelectChain={onSelectChain}
      onDisconnectWallet={disconnectWallet}
      onConnectWallet={connectWallet}
      onInitializeIdentification={initializeIdentification}
      onIdentify={onIdentify}
      onTimeout={onTimeout}
      onTryDemo={onTryDemo}
      onInstallMetamask={onInstallMetamask}
      onOpenInfoModal={onOpenInfoModal}
      onErrorTryAgain={onErrorTryAgain}
      onMetamaskTryAgain={onMetamaskTryAgain}
      onCloseInfoModal={onCloseInfoModal}
      onCloseMetamaskModal={onCloseMetamaskModal}
    />
  );
});
