import { FC, memo } from 'react';
import { Theme, theme } from '../../theme';
import { ClassNameProps, ThemeColor, ThemeColorProvider } from '@spatium/wallet-kit';

export type CloseProps = {
  size?: number;
  themeColor?: ThemeColor<Theme>;
};

export const Close: FC<CloseProps & ClassNameProps> = memo(({
  size = 15,
  themeColor = theme.colors.foreground,
  className,
  style,
}) => (
  <ThemeColorProvider themeColor={themeColor}>
    {(color) => (
      <svg className={className} style={style} width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="19.0602" height="1.27068" rx="0.63534" transform="matrix(0.704002 -0.710198 0.704002 0.710198 0.263062 13.5352)" fill={color} />
        <rect width="19.0602" height="1.27068" rx="0.63534" transform="matrix(0.704002 0.710198 -0.704002 0.710198 0.894531 0.136719)" fill={color} />
      </svg>
    )}
  </ThemeColorProvider>
));
