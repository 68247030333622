import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type EthereumProps = {
  size?: number;
};

export const Ethereum: FC<EthereumProps & ClassNameProps> = memo(({
  size = 20,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2041_6339)">
      <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#627EEA" />
      <path d="M10.3113 2.5V8.04375L14.9969 10.1375L10.3113 2.5Z" fill="white" fillOpacity="0.602" />
      <path d="M10.3112 2.5L5.625 10.1375L10.3112 8.04375V2.5Z" fill="white" />
      <path d="M10.3113 13.7317V17.4986L15 11.0117L10.3113 13.7317Z" fill="white" fillOpacity="0.602" />
      <path d="M10.3112 17.4986V13.7311L5.625 11.0117L10.3112 17.4986Z" fill="white" />
      <path d="M10.3113 12.86L14.9969 10.1394L10.3113 8.04688V12.86Z" fill="white" fillOpacity="0.2" />
      <path d="M5.625 10.1394L10.3112 12.86V8.04688L5.625 10.1394Z" fill="white" fillOpacity="0.602" />
    </g>
    <defs>
      <clipPath id="clip0_2041_6339">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
));
