import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './app';
import reportWebVitals from './reportWebVitals';

(window as any).Buffer = (window as any).Buffer || require('buffer').Buffer;

(Window as any).prototype._addEventListener = Window.prototype.addEventListener;

Window.prototype.addEventListener = function (a: any, b: any, c: any) {
  if (a === 'blur') { return };
  (this as any)._addEventListener(a, b, c);
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
