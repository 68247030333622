export const en = {
  'app.main.connect-wallet': 'CONNECT WALLET',
  'app.main.connect-meta': 'You will need to connect wallet to UNIKA and sign transaction to get anonymously verified. Do not worry, that is easy, just click button above',
  'app.main.welcome': 'Welcome to UNIKA',
  'app.main.help': 'We help you get verified to access Web3 services while keeping you anonymous even for UNIKA.',
  'app.main.tooltip': 'We link your public addresses with your encrypted biometrics. In the future versions we will do this in decentralised way within secure TEE environment. This way, no one will identify you. For now, we still keep you anonymous under our {privacy} & {terms}.',
  'app.main.privacy': 'Privacy Policy',
  'app.main.terms': 'Terms of Use',
  'app.main.wallet-connecting': 'Wallet is connecting',
  'app.main.ask-transaction': 'After clicking button we will ask you to sign transaction in your wallet.',
  'app.main.signing-process': 'Signing process',
  'app.main.waiting': 'Communicating with smart contract',
  'app.main.time-info': 'You\'ll have 10 minutes only to start and complete verification when your turn comes',
  'app.main.position': 'Your position:',
  'app.main.now': 'Now',
  'app.main.verification-time': 'Verification time left (min):',
  'app.main.start-verification': 'After clicking button we will ask you to sign a message and pass liveness test.',
  'app.main.starting': 'Launching liveness test',
  'app.main.magic': 'Please wait. Magic is happening',
  'app.main.verifying': 'We are anonymously linking addresses and data with biometrics. In a few moments you\'ll be verified.',
  'app.main.verified': 'You are verified',
  'app.main.verified-success': 'You was successfully verified on:',
  'app.main.verify-again': 'Verify again',
  'app.main.verify-again-info': 'Verify again if most up to date verification is required by the Web3 service',
  'app.main.web3': 'Now you can use Web3 services requiring UNIKA',
  'app.main.airdrop': 'DEMO AIRDROP',
  'app.main.try-demo': 'Try Demo',

  'app.main.info-modal.got': 'Got it',

  'app.main.support': 'Support',
  'app.main.cookie-banner': 'We use cookies on our website to ensure that we provide the best user experience for you. For more details please read our {privacy} & {terms}',
  'app.main.cookie-agree': 'I AGREE',

  'app.main.wallets-modal.title': 'SELECT CHAIN',

  'app.main.connect-wallets-modal.mobile-title': 'CONNECT YOUR',
  'app.main.connect-wallets-modal.title': 'CONNECT YOUR WALLET',
  'app.main.connect-wallets-modal.meta': 'MetaMask',
  'app.main.connect-wallets-modal.wallet-connect': 'wallet Connect',
  'app.main.connect-wallets-modal.coinbase': 'coinbase wallet',
  'app.main.connect-wallets-modal.bottom-text': 'By connecting a wallet, you agree with UNIKA {privacy} & {terms}',

  'app.main.button.get-verified': 'Get verified',
  'app.main.button.connecting': 'Connecting',
  'app.main.button.signing': 'Signing',
  'app.main.button.waiting': 'Waiting',
  'app.main.button.waiting-turn': 'Waiting{br}your turn',
  'app.main.button.start-verification': 'Start verification',
  'app.main.button.starting': 'Starting',
  'app.main.button.try-again': 'Try again',

  'app.main.metamask-modal.title': 'Connect your wallet',
  'app.main.metamask-modal.info': 'Please, install MetaMask extension and try again.',
  'app.main.metamask-modal.extension': 'Install extension',
  'app.main.metamask-modal.try-again': 'Try again',

  'app.main.error-modal.wrong': 'Something went wrong',
  'app.main.error-modal.try': 'Please try again.',
  'app.main.error-modal.message': 'If it happens again contact our support.',
  'app.main.error-modal.try-again': 'Try again',

  'snack.wallet-connected': 'Wallet is successfully connected',
  'snack.tx-sent': 'Transaction successfully signed and sent',
  'snack.placed-in-queue': 'You\'re placed to UNIKA Network Queue for verifying.',
  'snack.validator-assigned': 'Validator is successfully assigned. Please, start and complete verification within 10 minutes',
  'snack.verification-timeout': 'Verification time expired. Please start again',
  'snack.operation-canceled': 'Operation canceled',
  'snack.context-switched': 'Identification failed. Please, do not switch to other apps during the identification process',
  'snack.metamask-not-installed': 'Metamask wallet is not installed',
  'snack.unsupported-chain': 'Network selected in the wallet is not supported. Please, change the network',
  'snack.unknown-error': 'Something went wrong. Please, try again',
};

export default en;
