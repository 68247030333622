import styled, { createGlobalStyle } from 'styled-components';

export const ScreenRoot = styled.div`
  position: absolute;

  right: 0;
  left: 0;
  bottom: 0;
  top: 0;

  overflow: hidden;
  background: transparent;

  & > div, & > div > * {
    width: 100%;
    height: 100%;
  }
`;

export const TransitionWrapper = styled.div`
  position: absolute;

  right: 0;
  left: 0;
  bottom: 0;
  top: 0;

  overflow: hidden;
  background: transparent;

  & > div, & > div > * {
    width: 100%;
    height: 100%;
  }
`;

export const ModalRoot = styled.div`
  position: absolute;

  right: 0;
  left: 0;
  bottom: 0;
  top: 0;

  pointer-events: none;
  overflow: hidden;
  z-index: 100;

  & > * {
    width: 100%;
    height: 100%;
  }
`;

export const SnackRoot = styled.div`
  position: fixed;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 35px 20px;
  box-sizing: border-box;

  pointer-events: none;
  z-index: 101;
`;

type InsetProps = {
  insetTop?: number;
  insetBottom?: number;
  insetLeft?: number;
  insetRight?: number;
};

export const GlobalStyle = createGlobalStyle<InsetProps>`
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Quicksand-Light'), local('sans-serif'), url('./fonts/Quicksand/Quicksand-Light.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Quicksand-Regular'), local('sans-serif'), url('./fonts/Quicksand/Quicksand-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Quicksand-Medium'), local('sans-serif'), url('./fonts/Quicksand/Quicksand-Medium.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Quicksand-SemiBold'), local('sans-serif'), url('./fonts/Quicksand/Quicksand-SemiBold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Quicksand-Bold'), local('sans-serif'), url('./fonts/Quicksand/Quicksand-Bold.ttf') format('truetype');
  }
  
  body {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    
    margin: 0;
    background: #F5F5F5;

    --safe-area-inset-top-default: ${({ insetTop = '0px' }) => insetTop};
    --safe-area-inset-bottom-default: ${({ insetBottom = '0px' }) => insetBottom};
    --safe-area-inset-left-default: ${({ insetLeft = '0px' }) => insetLeft};
    --safe-area-inset-right-default: ${({ insetRight = '0px' }) => insetRight};

    * {
      box-sizing: border-box;
    }
  }

  #root {
    width: 100%;
    height: 100%;
    position: relative;
  }

  h1 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
  }

  .cookie__container {
    width: 100%;
    padding: 16px 0px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background: #EDF1F2;
    position: fixed;
    bottom: 0;
    z-index: 60;
  }

  .cookie__text {
    margin-left: 40px;
    margin-right: 12px;

    a {
      color: #233045;
      text-decoration: underline;
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-left: 20px;
    }
  }

  .cookie__btn-wrapper {
    margin-right: 40px;

    @media screen and (max-width: 768px) {
      margin-right: 20px;
    }
  }

  .cookie__btn {
    width: 89px;
    height: 32px;
    background: linear-gradient(259.18deg, #05D2FF -20.75%, #2BF5FF 34.12%, #04F8CA 88.11%);
    border: none;
    border-radius: 5px;
    
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #233045;
    cursor: pointer;
  }
`;
