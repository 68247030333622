import { FC, memo } from 'react';
import { Theme, theme } from '../../theme';
import { ClassNameProps, ThemeColor, ThemeColorProvider } from '@spatium/wallet-kit';

export type MobileLogoProps = {
  size?: number;
  themeColor?: ThemeColor<Theme>;
};

export const MobileLogo: FC<MobileLogoProps & ClassNameProps> = memo(({
  size = 30,
  themeColor = theme.colors.foreground,
  className,
  style,
}) => (
  <ThemeColorProvider themeColor={themeColor}>
    {(color) => (
      <svg className={className} style={style} width={size} height={20} viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.6" d="M20 0C17.3976 0.0623879 14.919 1.11151 13.0762 2.93056C12.132 3.85572 11.3829 4.95709 10.8722 6.17084C10.3614 7.38459 10.0993 8.68657 10.101 10.0013V20C10.1351 20 9.89637 20 9.89637 20C7.25302 19.9457 4.73644 18.8687 2.88638 17C1.03632 15.1313 0.000116116 12.6197 0 10.0039L0 0.609993C0 0.448213 0.0649583 0.293059 0.180585 0.178663C0.296211 0.064267 0.453035 0 0.616555 0L20 0Z" fill="url(#paint0_linear_1717_5980)" />
        <path opacity="0.6" d="M10 20C12.6024 19.9376 15.081 18.8885 16.9238 17.0694C17.868 16.1443 18.6171 15.0429 19.1278 13.8292C19.6386 12.6154 19.9007 11.3134 19.899 9.9987L19.899 -8.83058e-07C19.8649 -8.8604e-07 20.1036 -8.65168e-07 20.1036 -8.65168e-07C22.747 0.0543245 25.2636 1.13134 27.1136 3.00004C28.9637 4.86875 29.9999 7.38032 30 9.99611L30 19.39C30 19.5518 29.935 19.7069 29.8194 19.8213C29.7038 19.9357 29.547 20 29.3834 20L10 20Z" fill="url(#paint1_linear_1717_5980)" />
        <g filter="url(#filter0_d_1717_5980)">
          <path d="M20 0V10.0039C19.9925 12.6523 18.9366 15.19 17.0629 17.063C15.1892 18.9359 12.6501 19.9918 10 20V10.0013C10.0062 7.35203 11.0615 4.81292 12.9353 2.93887C14.8091 1.06482 17.349 0.00822466 20 0V0Z" fill="url(#paint2_linear_1717_5980)" />
        </g>
        <defs>
          <filter id="filter0_d_1717_5980" x="6" y="0" width="18" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.176471 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1717_5980" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1717_5980" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_1717_5980" x1="20.6977" y1="-22.2222" x2="-9.84452" y2="-16.3875" gradientUnits="userSpaceOnUse">
            <stop stopColor="#05D2FF" />
            <stop offset="0.412877" stopColor="#2BF5FF" />
            <stop offset="0.819136" stopColor="#04F8CA" />
          </linearGradient>
          <linearGradient id="paint1_linear_1717_5980" x1="9.30232" y1="42.2222" x2="39.8445" y2="36.3875" gradientUnits="userSpaceOnUse">
            <stop stopColor="#05D2FF" />
            <stop offset="0.412877" stopColor="#2BF5FF" />
            <stop offset="0.819136" stopColor="#04F8CA" />
          </linearGradient>
          <linearGradient id="paint2_linear_1717_5980" x1="20.3488" y1="-22.2222" x2="4.66353" y2="-20.724" gradientUnits="userSpaceOnUse">
            <stop stopColor="#05D2FF" />
            <stop offset="0.412877" stopColor="#2BF5FF" />
            <stop offset="0.819136" stopColor="#04F8CA" />
          </linearGradient>
        </defs>
      </svg>

    )}
  </ThemeColorProvider>
));
