import { FC, memo, useState } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

import { theme } from 'theme';
import { FadeSwitchWrapper } from 'components/fade-switch-wrapper';

import { MetaButtonBase, ButtonInitial, ButtonHover, TextWrapper, Number } from './meta-button.styles';

export type MetaButtonProps = {
  number?: number;
};

export const MetaButton: FC<MetaButtonProps & ClassNameProps> = memo(({ className, style, children, number }) => {
  const [hover, setHover] = useState(false);

  return (
    <MetaButtonBase className={className} style={style} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <ButtonInitial />

      <FadeSwitchWrapper transitionDuration={100} switchKey={hover}>
        {hover &&
          <ButtonHover />}
      </FadeSwitchWrapper>

      <TextWrapper verticalAlign="center" horizontalAlign="center">
        {children}
      </TextWrapper>

      {number &&
        <Number
          themeFont={theme.fonts.q4.override({ size: theme.space(12), lineHeight: theme.space(15) })}
          themeTextColor={theme.colors.grayAlt}>
          {number}
        </Number>}
    </MetaButtonBase>
  )
});
