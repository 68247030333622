import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type LogoutIconProps = {
  size?: number;
};

export const LogoutIcon: FC<LogoutIconProps & ClassNameProps> = memo(({
  size = 24,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 12L17 16V13H8V11H17V8L22 12Z" fill="#F82F2F" />
    <path d="M10.999 3.00003C12.1815 2.99676 13.3529 3.22814 14.4453 3.6808C15.5377 4.13345 16.5294 4.79837 17.363 5.63703L15.949 7.05103C14.627 5.72903 12.869 5.00003 10.999 5.00003C9.12904 5.00003 7.37104 5.72903 6.04904 7.05103C4.72704 8.37303 3.99804 10.131 3.99804 12.001C3.99804 13.871 4.72704 15.629 6.04904 16.951C7.37104 18.273 9.12904 19.002 10.999 19.002C12.869 19.002 14.627 18.273 15.949 16.951L17.363 18.365C15.664 20.065 13.404 21.002 10.999 21.002C8.59404 21.002 6.33404 20.065 4.63504 18.365C2.93504 16.666 1.99804 14.406 1.99804 12.001C1.99804 9.59604 2.93504 7.33603 4.63504 5.63703C5.46864 4.79837 6.46037 4.13345 7.55278 3.6808C8.64519 3.22814 9.81656 2.99676 10.999 3.00003Z" fill="#F82F2F" />
  </svg>
));
