import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type ButtonDisableProps = {
  size?: number;
};

export const ButtonDisable: FC<ButtonDisableProps & ClassNameProps> = memo(({
  size = 240,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={60} viewBox="0 0 240 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_biiii_342_944)">
      <path d="M0 12.5748V41.6964C0 43.2962 0.63889 44.8298 1.77473 45.9564L3.59497 47.7618C4.71875 48.8764 6.23744 49.5018 7.82024 49.5018H24.5168C26.5043 49.5018 28.3628 50.486 29.4797 52.13L33.0411 57.3718C34.158 59.0158 36.0165 60 38.004 60H227.497C228.674 60 229.825 59.6538 230.806 59.0045L237.31 54.7034C238.99 53.5924 240 51.7128 240 49.6989V23.8284V20.1202C240 18.5611 239.393 17.0632 238.308 15.9438L234.337 11.848C233.207 10.6824 231.653 10.0244 230.029 10.0244H215.909C213.881 10.0244 211.991 9.00045 210.883 7.3022L207.896 2.72223C206.788 1.02398 204.898 0 202.871 0H12.8222C11.2692 0 9.77673 0.602124 8.65855 1.67977L1.83639 8.25461C0.662937 9.38553 0 10.9451 0 12.5748Z" fill="#96FDEA" />
    </g>
    <defs>
      <filter id="filter0_biiii_342_944" x="-100" y="-100" width="440" height="260" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="50" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_342_944" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_342_944" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="9" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_342_944" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="98" />
        <feGaussianBlur stdDeviation="50" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect2_innerShadow_342_944" result="effect3_innerShadow_342_944" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-82" />
        <feGaussianBlur stdDeviation="34" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="effect3_innerShadow_342_944" result="effect4_innerShadow_342_944" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="7" />
        <feGaussianBlur stdDeviation="5.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
        <feBlend mode="normal" in2="effect4_innerShadow_342_944" result="effect5_innerShadow_342_944" />
      </filter>
    </defs>
  </svg>
));
