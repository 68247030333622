import { FC, memo, Ref, RefObject, useCallback, useMemo, useState } from 'react';
import { layout, OptionalHandle, Portal, Pressable, Translated, useOptionalRef, useWindowDimensions } from '@spatium/wallet-kit';
import Countdown, { zeroPad } from 'react-countdown';
import ClickAwayListener from 'react-click-away-listener';
import Jazzicon from 'react-jazzicon'
import { Chain, Connector, useConnect } from 'wagmi'

import { ChainObject, chains } from 'lib/chains';

import { theme, Spacing, Text, Column, Row } from 'theme';
import { HeadedView } from 'views/headed';

import { VerifyButton } from 'components/verify-button';
import { ButtonLoader } from 'components/button-loader';
import { CornerBorderButton } from 'components/corner-border-button';
import { ModalContainer } from 'components/modal';
import { FadeSwitchWrapper } from 'components/fade-switch-wrapper';
import { MetaButton } from 'components/meta-button';
import { Cookie } from 'components/cookie';

import { AttentionIcon } from 'assets/attention-icon';
import { QuestionIcon } from 'assets/question-icon';
import { DottedLine } from 'assets/dotted-line';
import { VerifiedCheckedIcon } from 'assets/verified-checked-icon';
import { BackgroundRectangle } from 'assets/background-rectangle';
import { VerifiedDottedLine } from 'assets/verified-dotted-line';
import { LogoutIcon } from 'assets/logout-icon';
import { UnikaLogo } from 'assets/unika-logo';
import { MetaLogo } from 'assets/meta-logo';
import { WalletConnect } from 'assets/wallet-connect';
import { Coinbase } from 'assets/coinbase';
import { Support } from 'assets/support';
import { MobileLogo } from 'assets/mobile-logo';

import { Status } from './main';
import {
  Scrollable,
  SecondaryButton,
  ModalButtonWrapper,
  Close,
  Title,
  Info,
  LogoWrapper,
  BaseTextWrapper,
  IconWrapper,
  HelpInfo,
  ToolTip,
  ModalWrapper,
  GetVerifiedBase,
  InfoText,
  VerifiedBase,
  RectangleWrapper,
  Wrapper,
  AirDrop,
  Verified,
  StatusWrapper,
  Link,
  BottomModalWrapper,
  ChainsWrapper,
  SelectedChain,
  ChainDropdown,
  ChainItem,
  Arrow,
  ChainsListModal,
  ConnectWalletModalWrapper,
  ConnectWalletWrapper,
  ConnectWalletItem,
  SectionWrapper,
  SupportLink,
} from './main.styles';

type BaseModalProps = {
  modalRef: Ref<OptionalHandle>;
  onClose?: () => void;
};

const InfoModal: FC<BaseModalProps> = memo(({ modalRef, onClose }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} themeColor={theme.colors.foreground} themePadding={[theme.space(16)]}>
      {() => (
        <ModalWrapper themePadding={[theme.space(32), theme.space(12)]}>
          <Info themeFont={theme.fonts.q7.override({ size: theme.space(14), lineHeight: theme.space(18) })} themeTextColor={theme.colors.foreground} horizontalAlign="center">
            <Translated id="app.main.tooltip" values={{
              privacy: <Link href={`${process.env.REACT_APP_UNIKA_WEBSITE}/privacy-policy.pdf`} target="_blank"><Translated id="app.main.privacy" /></Link>,
              terms: <Link href={`${process.env.REACT_APP_UNIKA_WEBSITE}/terms-of-use.pdf`} target="_blank"><Translated id="app.main.terms" /></Link>
            }} />
          </Info>

          <Spacing themeSpace={theme.space(16)} />

          <Pressable onClick={onClose}>
            <SecondaryButton>
              <Info themeFont={theme.fonts.q3} themeTextColor={theme.colors.foreground} transform="uppercase" horizontalAlign="center">
                <Translated id="app.main.info-modal.got" />
              </Info>
            </SecondaryButton>
          </Pressable>
        </ModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

type WalletsModalProps = BaseModalProps & {
  connectors: Connector[];
  onConnect: (connector: Connector) => void;
};

const WalletsModalDesktop: FC<WalletsModalProps> = memo(({ modalRef, connectors, onClose, onConnect }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.background} themePadding={theme.space(16)}>
      {() => (
        <ConnectWalletModalWrapper horizontalAlign="center">
          <Pressable onClick={onClose}>
            <Close horizontalAlign="center" verticalAlign="center" />
          </Pressable>
          <Title themeFont={theme.fonts.q1} themeTextColor={theme.colors.foreground} transform="uppercase" horizontalAlign='center'>
            <Translated id="app.main.connect-wallets-modal.title" />
          </Title>

          <Spacing themeSpace={theme.space(40)} />

          <ConnectWalletWrapper verticalAlign="center" horizontalAlign="space-between">
            {connectors.map((connector) => connector.ready && (
              <Pressable key={connector.id} onClick={() => onConnect(connector)}>
                <ConnectWalletItem verticalAlign="center" horizontalAlign="center">
                  {connector.id === 'metaMask' ? <>
                    <MetaLogo size={60} />
                    <Spacing themeSpace={theme.space(16)} />
                    <Info themeFont={theme.fonts.q3} themeTextColor={theme.colors.blackLight} transform="uppercase" horizontalAlign='center'>
                      <Translated id="app.main.connect-wallets-modal.meta" />
                    </Info>
                  </> : connector.id === 'walletConnect' ? <>
                    <WalletConnect size={60} />
                    <Spacing themeSpace={theme.space(16)} />
                    <Info themeFont={theme.fonts.q3} themeTextColor={theme.colors.blackLight} transform="uppercase" horizontalAlign='center'>
                      <Translated id="app.main.connect-wallets-modal.wallet-connect" />
                    </Info>
                  </> : connector.id === 'coinbaseWallet' ? <>
                    <Coinbase size={60} />
                    <Spacing themeSpace={theme.space(16)} />
                    <Info themeFont={theme.fonts.q3} themeTextColor={theme.colors.blackLight} transform="uppercase" horizontalAlign='center'>
                      <Translated id="app.main.connect-wallets-modal.coinbase" />
                    </Info>
                  </> : <>
                    <Info themeFont={theme.fonts.q3} themeTextColor={theme.colors.blackLight} transform="uppercase" horizontalAlign='center'>
                      Unknown connector
                    </Info>
                  </>}
                </ConnectWalletItem>
              </Pressable>
            ))}
          </ConnectWalletWrapper>

          <Spacing themeSpace={theme.space(40)} />

          <Text themeFont={theme.fonts.q7} themeTextColor={theme.colors.blackLight}>
            <Translated id="app.main.connect-wallets-modal.bottom-text" values={{
              privacy: <Link href="./UNIKA-PRIVACY-POLICY.pdf" target="_blank"><Translated id="app.main.privacy" /></Link>,
              terms: <Link href="./terms-of-use-UNIKA.pdf" target="_blank"><Translated id="app.main.terms" /></Link>
            }} />
          </Text>
        </ConnectWalletModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

const WalletsMobileModal: FC<WalletsModalProps> = memo(({ modalRef, connectors, onClose, onConnect }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.background} themePadding={theme.space(16)}>
      {() => (
        <BottomModalWrapper>
          <Pressable onClick={onClose}>
            <Close horizontalAlign="center" verticalAlign="center" />
          </Pressable>
          <Title themeFont={theme.fonts.q1.override({ size: theme.space(26), lineHeight: theme.space(32) })} themeTextColor={theme.colors.blackLight} transform="uppercase">
            <Translated id="app.main.connect-wallets-modal.mobile-title" />
          </Title>

          <Spacing themeSpace={theme.space(12)} />

          <ConnectWalletWrapper themeSpacing={theme.space(8)}>
            {connectors.map((connector) => connector.ready && (
              <Pressable key={connector.id} onClick={() => onConnect(connector)}>
                <ConnectWalletItem>
                  {connector.id === 'metaMask' ? <>
                    <Spacing themeSpace={theme.space(16)} />
                    <MetaLogo size={40} />
                    <Spacing themeSpace={theme.space(16)} />
                    <Info themeFont={theme.fonts.q3} themeTextColor={theme.colors.blackLight} transform="uppercase">
                      <Translated id="app.main.connect-wallets-modal.meta" />
                    </Info>
                  </> : connector.id === 'walletConnect' ? <>
                    <Spacing themeSpace={theme.space(16)} />
                    <WalletConnect size={40} />
                    <Spacing themeSpace={theme.space(16)} />
                    <Info themeFont={theme.fonts.q3} themeTextColor={theme.colors.blackLight} transform="uppercase">
                      <Translated id="app.main.connect-wallets-modal.wallet-connect" />
                    </Info>
                  </> : connector.id === 'coinbaseWallet' ? <>
                    <Spacing themeSpace={theme.space(16)} />
                    <Coinbase size={40} />
                    <Spacing themeSpace={theme.space(16)} />
                    <Info themeFont={theme.fonts.q3} themeTextColor={theme.colors.blackLight} transform="uppercase">
                      <Translated id="app.main.connect-wallets-modal.coinbase" />
                    </Info>
                  </> : <>
                    <Info themeFont={theme.fonts.q3} themeTextColor={theme.colors.blackLight} transform="uppercase" horizontalAlign='center'>
                      Unknown connector
                    </Info>
                  </>}
                </ConnectWalletItem>
                <Spacing themeSpace={theme.space(16)} />
              </Pressable>
            ))}
          </ConnectWalletWrapper>

          <Spacing themeSpace={theme.space(16)} />

          <Text themeFont={theme.fonts.q7.override({ size: theme.space(14), lineHeight: theme.space(18) })} themeTextColor={theme.colors.blackLight}>
            <Translated id="app.main.connect-wallets-modal.bottom-text" values={{
              privacy: <Link href="./UNIKA-PRIVACY-POLICY.pdf" target="_blank"><Translated id="app.main.privacy" /></Link>,
              terms: <Link href="./terms-of-use-UNIKA.pdf" target="_blank"><Translated id="app.main.terms" /></Link>
            }} />
          </Text>
        </BottomModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

type ChainsModalProps = BaseModalProps & {
  chains: Array<ChainObject>;
  selected: ChainObject;
  onSelect: (chain: ChainObject) => void;
};

const ChainsModal: FC<ChainsModalProps> = memo(({ chains, selected, modalRef, onSelect, onClose }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.background} themePadding={theme.space(16)}>
      {() => (
        <BottomModalWrapper>
          <Pressable onClick={onClose}>
            <Close horizontalAlign="center" verticalAlign="center" />
          </Pressable>
          <Title themeFont={theme.fonts.q1.override({ size: theme.space(26), lineHeight: theme.space(32) })} themeTextColor={theme.colors.blackLight} transform="uppercase">
            <Translated id="app.main.wallets-modal.title" />
          </Title>
          <Spacing themeSpace={theme.space(12)} />
          <ChainsListModal>
            {chains.map((x) => (
              <ChainItem key={x.name} onClick={() => onSelect(x)} active={x.name === selected.name}>
                {x.icon}
                <Spacing themeSpace={theme.space(8)} />
                <Text themeFont={theme.fonts.q6.override({ weight: 600 })} themeTextColor={theme.colors.blackLight}>
                  {x.name}
                </Text>
              </ChainItem>
            ))}
          </ChainsListModal>
        </BottomModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

type MetamaskModalProps = BaseModalProps & {
  onInstall: () => void;
  onTryAgain: () => void;
};

const MetamaskModal: FC<MetamaskModalProps> = memo(({ modalRef, onInstall, onTryAgain, onClose }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.background} themePadding={theme.space(16)}>
      {() => (
        <ModalWrapper horizontalAlign="center">
          <Pressable onClick={onClose}>
            <Close horizontalAlign="center" verticalAlign="center" />
          </Pressable>

          <Column horizontalAlign="center">
            <Title themeFont={theme.fonts.q1} themeTextColor={theme.colors.foreground} transform="uppercase" horizontalAlign='center'>
              <Translated id="app.main.metamask-modal.title" />
            </Title>
            <Spacing themeSpace={theme.space(12)} />
            <Info themeFont={theme.fonts.q6} themeTextColor={theme.colors.foreground} horizontalAlign='center'>
              <Translated id="app.main.metamask-modal.info" />
            </Info>
          </Column>

          <Spacing themeSpace={theme.space(40)} />

          <ModalButtonWrapper>
            <Pressable onClick={onInstall}>
              <MetaButton number={1}>
                <MetaLogo />
                <Spacing themeSpace={theme.space(16)} />
                <Text
                  horizontalAlign='center'
                  transform='uppercase'
                  themeFont={theme.fonts.q3}
                  themeTextColor={theme.colors.foreground}
                >
                  <Translated id="app.main.metamask-modal.extension" />
                </Text>
              </MetaButton>
            </Pressable>

            <Spacing themeSpace={theme.space(20)} />

            <Pressable onClick={onTryAgain}>
              <VerifyButton number={2}>
                <Text
                  horizontalAlign='center'
                  transform='uppercase'
                  themeFont={theme.fonts.q3}
                  themeTextColor={theme.colors.foreground}
                >
                  <Translated id="app.main.metamask-modal.try-again" />
                </Text>
              </VerifyButton>
            </Pressable>
          </ModalButtonWrapper>
        </ModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

type ErrorModalProps = BaseModalProps & {
  innerWidth: number;
  onTryAgain: () => void;
};

const ErrorModal: FC<ErrorModalProps> = memo(({ innerWidth, modalRef, onTryAgain }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.foreground} themePadding={theme.space(16)}>
      {() => (
        <ModalWrapper horizontalAlign="center" themePadding={[theme.space(32), theme.space(18)]}>
          <AttentionIcon {...innerWidth <= 768 && { size: 40 }} />
          <Spacing themeSpace={theme.space(innerWidth > 768 ? 40 : 16)} />

          <Title horizontalAlign="center" themeFont={theme.fonts.q1} themeTextColor={theme.colors.blackLight} transform="uppercase">
            <Translated id="app.main.error-modal.wrong" />
          </Title>

          <Spacing themeSpace={theme.space(innerWidth > 768 ? 10 : 12)} />

          <Info horizontalAlign="center" themeFont={theme.fonts.q6} themeTextColor={theme.colors.blackLight}>
            <Translated id="app.main.error-modal.try" />
          </Info>
          <Info horizontalAlign="center" themeFont={theme.fonts.q6} themeTextColor={theme.colors.blackLight}>
            <Translated id="app.main.error-modal.message" />
          </Info>

          <Spacing themeSpace={theme.space(innerWidth > 768 ? 40 : 16)} />

          <Pressable onClick={onTryAgain}>
            {innerWidth > 768
              ? <VerifyButton>
                <Text
                  horizontalAlign='center'
                  transform='uppercase'
                  themeFont={theme.fonts.q3}
                  themeTextColor={theme.colors.blackLight}
                >
                  <Translated id="app.main.error-modal.try-again" />
                </Text>
              </VerifyButton>
              : <SecondaryButton>
                <Info themeFont={theme.fonts.q3} themeTextColor={theme.colors.foreground} transform="uppercase" horizontalAlign="center">
                  <Translated id="app.main.error-modal.try-again" />
                </Info>
              </SecondaryButton>}
          </Pressable>
        </ModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

export type MainViewProps = {
  status: Status;
  address?: string;
  selectedChain: ChainObject;
  connectedChain?: Chain;
  identificationTimestamp?: Date;
  claimTimestamp?: Date;
  requestPosition?: number;
  infoModalRef: RefObject<OptionalHandle<{}>>;
  metamaskModalRef: RefObject<OptionalHandle<{}>>;
  errorModalRef: RefObject<OptionalHandle<{}>>;
  onSelectChain: (selected: ChainObject) => void;
  onConnectWallet: (connector: Connector, chaindId: number) => void;
  onDisconnectWallet: () => void;
  onInitializeIdentification: () => void;
  onIdentify: () => void;
  onTimeout: () => void;
  onTryDemo: () => void;
  onInstallMetamask: () => void;
  onErrorTryAgain: () => void;
  onMetamaskTryAgain: () => void;
  onOpenInfoModal: () => void;
  onCloseInfoModal: () => void;
  onCloseMetamaskModal: () => void;
};

export const MainView: FC<MainViewProps> = memo(({
  status,
  address,
  selectedChain,
  connectedChain,
  claimTimestamp,
  identificationTimestamp,
  requestPosition,
  infoModalRef,
  metamaskModalRef,
  errorModalRef,
  onSelectChain,
  onConnectWallet,
  onDisconnectWallet,
  onInitializeIdentification,
  onIdentify,
  onTimeout,
  onTryDemo,
  onInstallMetamask,
  onErrorTryAgain,
  onMetamaskTryAgain,
  onOpenInfoModal,
  onCloseInfoModal,
  onCloseMetamaskModal,
}) => {
  const _account = useMemo(() => (
    address && `${address.slice(0, 6)}...${address.slice(address.length - 4)}`.toUpperCase()
  ), [address]);

  const { innerWidth } = useWindowDimensions();
  const [tooltip, setTooltip] = useState(false);

  const currentChain = useMemo(() => {
    return chains.find((x) => x.id === connectedChain?.id) ?? selectedChain;
  }, [connectedChain, selectedChain]);

  const [openChainDropdown, setOpenChainDropdown] = useState(false);
  const [chainsModalRef, openChainsModal, closeChainsModal] = useOptionalRef();
  const [connectWalletModalRef, openConnectWalletModal, closeConnectWalletModal] = useOptionalRef();

  const _onSelectChain = useCallback((selected: ChainObject) => {
    setOpenChainDropdown(false);
    closeChainsModal();

    onSelectChain(selected);
  }, [closeChainsModal, onSelectChain]);

  const onConnect = useCallback((connector: Connector) => {
    closeConnectWalletModal();
    onConnectWallet(connector, selectedChain.id);
  }, [onConnectWallet, closeConnectWalletModal, selectedChain.id]);

  const { connectors } = useConnect();

  const loading = useMemo(() =>
    status === 'connecting' ||
    status === 'signing' ||
    status === 'sending' ||
    status === 'queued' ||
    status === 'assigned' ||
    status === 'signing-message' ||
    status === 'starting',
    [status]);

  return (
    <HeadedView
      left={
        <LogoWrapper>
          {innerWidth > 768 ? <UnikaLogo /> : <MobileLogo />}
        </LogoWrapper>}
      right={
        <Row verticalAlign="center">
          <ClickAwayListener onClickAway={() => setOpenChainDropdown(false)}>
            <ChainsWrapper>
              <SelectedChain
                verticalAlign="center"
                dropdown={openChainDropdown}
                onClick={innerWidth > 768
                  ? () => setOpenChainDropdown(prev => !prev)
                  : openChainsModal}
              >
                {currentChain.icon}
                <Spacing themeSpace={theme.space(innerWidth > 768 ? 10 : 4)} />
                {innerWidth > 768 && <>
                  <Info themeFont={theme.fonts.q6.override({ size: theme.space(20), lineHeight: theme.space(25) })}>
                    {currentChain.name}
                  </Info>
                  <Spacing themeSpace={theme.space(10)} />
                </>}
                <Arrow down={openChainDropdown} />
              </SelectedChain>
              <FadeSwitchWrapper switchKey={openChainDropdown} transitionDuration={200}>
                {openChainDropdown &&
                  <ChainDropdown>
                    {chains.map((x) => (
                      <ChainItem key={x.name} onClick={() => _onSelectChain(x)} active={x.name === currentChain.name}>
                        {x.icon}
                        <Spacing themeSpace={theme.space(8)} />
                        <Text themeFont={theme.fonts.q6.override({ weight: 600 })} themeTextColor={theme.colors.blackLight}>
                          {x.name}
                        </Text>
                      </ChainItem>
                    ))}
                  </ChainDropdown>}
              </FadeSwitchWrapper>
            </ChainsWrapper>
          </ClickAwayListener>
          <Spacing themeSpace={theme.space(innerWidth > 768 ? 32 : 16)} />
          <FadeSwitchWrapper switchKey={_account ?? ''}>
            {address ?
              <Verified verticalAlign="center">
                <Jazzicon diameter={30} seed={parseInt(address.slice(2, 10), 16)} />
                <Spacing themeSpace={theme.space(16)} />
                <Text themeFont={theme.fonts.q3} themeTextColor={theme.colors.blackLight}>
                  {_account}
                </Text>
                <Spacing themeSpace={theme.space(16)} />

                <Pressable onClick={onDisconnectWallet}>
                  <LogoutIcon />
                </Pressable>
              </Verified>
              : <Pressable disabled={status === 'connecting'} onClick={openConnectWalletModal}>
                {({ disabled }) => (
                  <CornerBorderButton disabled={disabled} {...innerWidth <= 768 && { size: 144 }}>
                    <Text themeFont={innerWidth > 768 ? theme.fonts.q3 : theme.fonts.q3.override({ size: theme.space(12), lineHeight: theme.space(18) })} themeTextColor={theme.colors.grayLight} transform="uppercase" horizontalAlign="center">
                      <Translated id="app.main.connect-wallet" />
                    </Text>
                  </CornerBorderButton>
                )}
              </Pressable>}
          </FadeSwitchWrapper>
        </Row>
      }>
      <Scrollable horizontalAlign="stretch" {...layout.expand}>
        <Column horizontalAlign="center" themePadding={[theme.space(0), theme.space(16)]}>
          <FadeSwitchWrapper switchKey={status === 'initial' || status === 'connecting' || status === 'connected' || status === 'signing' || status === 'sending' || status === 'queued' || status === 'assigned' || status === 'claimed' || status === 'signing-message' || status === 'starting' || status === 'timeout'}>
            {(status === 'initial' || status === 'connecting' || status === 'connected' || status === 'signing' || status === 'sending' || status === 'queued' || status === 'assigned' || status === 'claimed' || status === 'signing-message' || status === 'starting' || status === 'timeout') &&
              <SectionWrapper horizontalAlign='center' verticalAlign="center">
                <BaseTextWrapper horizontalAlign='center'>
                  <Title themeFont={innerWidth > 768 ? theme.fonts.q4 : theme.fonts.q4.override({ size: theme.space(26), lineHeight: theme.space(32) })} themeTextColor={theme.colors.blackLight} transform="uppercase" horizontalAlign="center">
                    <h1><Translated id="app.main.welcome" /></h1>
                  </Title>

                  <Spacing themeSpace={theme.space(20)} />

                  <HelpInfo themeFont={innerWidth > 768 ? theme.fonts.q5 : theme.fonts.q6} themeTextColor={theme.colors.blackLight} horizontalAlign="center">
                    <Translated id="app.main.help" />

                    <IconWrapper {...innerWidth > 768
                      ? { onMouseEnter: () => setTooltip(true), onMouseLeave: () => setTooltip(false) }
                      : { onClick: onOpenInfoModal }}
                    >
                      <QuestionIcon size={15} />
                      <FadeSwitchWrapper switchKey={tooltip}>
                        {tooltip &&
                          <ToolTip themeFont={theme.fonts.q8} themeTextColor={theme.colors.blackLight} horizontalAlign="center">
                            <Translated id="app.main.tooltip" values={{
                              privacy: <Link href={`${process.env.REACT_APP_UNIKA_WEBSITE}/privacy-policy.pdf`} target="_blank"><Translated id="app.main.privacy" /></Link>,
                              terms: <Link href={`${process.env.REACT_APP_UNIKA_WEBSITE}/terms-of-use.pdf`} target="_blank"><Translated id="app.main.terms" /></Link>
                            }} />
                          </ToolTip>}
                      </FadeSwitchWrapper>
                    </IconWrapper>
                  </HelpInfo>
                </BaseTextWrapper>

                <Spacing themeSpace={theme.space(40)} />

                <GetVerifiedBase horizontalAlign="center" verticalAlign="center">
                  <Pressable disabled={loading} onClick={status === 'claimed' ? onIdentify : status === 'connected' || status === 'timeout' ? onInitializeIdentification : openConnectWalletModal}>
                    <VerifyButton loading={loading}>
                      <FadeSwitchWrapper switchKey={status}>
                        <Text
                          horizontalAlign='center'
                          transform='uppercase'
                          themeFont={theme.fonts.q3}
                          themeTextColor={loading ? theme.colors.grayAlt : theme.colors.blackLight}
                        >
                          {status === 'starting'
                            ? <Translated id="app.main.button.starting" />
                            : status === 'queued' || status === 'assigned'
                              ? <Translated id="app.main.button.waiting-turn" />
                              : status === 'signing' || status === 'signing-message'
                                ? <Translated id="app.main.button.signing" />
                                : status === 'sending'
                                  ? <Translated id="app.main.button.waiting" />
                                  : status === 'connecting'
                                    ? <Translated id="app.main.button.connecting" />
                                    : status === 'claimed'
                                      ? <Translated id="app.main.button.start-verification" />
                                      : status === 'timeout'
                                        ? <Translated id="app.main.button.try-again" />
                                        : <Translated id="app.main.button.get-verified" />}
                        </Text>
                      </FadeSwitchWrapper>
                    </VerifyButton>
                  </Pressable>

                  <Spacing themeSpace={theme.space(12)} />

                  <StatusWrapper horizontalAlign="center">
                    <FadeSwitchWrapper switchKey={status}>
                      <Text horizontalAlign="center" themeFont={theme.fonts.q7} themeTextColor={theme.colors.blackLight}>
                        {status === 'starting'
                          ? <Translated id="app.main.starting" />
                          : status === 'claimed'
                            ? <Translated id="app.main.start-verification" />
                            : status === 'signing' || status === 'signing-message'
                              ? < Translated id="app.main.signing-process" />
                              : status === 'sending'
                                ? <Translated id="app.main.waiting" />
                                : status === 'connecting'
                                  ? <Translated id="app.main.wallet-connecting" />
                                  : status === 'connected' || status === 'timeout'
                                    ? <Translated id="app.main.ask-transaction" />
                                    : status === 'initial' &&
                                    <Translated id="app.main.connect-meta" />}
                      </Text>
                    </FadeSwitchWrapper>

                    <FadeSwitchWrapper switchKey={status}>
                      {(status === 'queued' || status === 'assigned' || status === 'claimed' || status === 'timeout') && <>
                        <Spacing themeSpace={theme.space(28)} />
                        <DottedLine />
                      </>}
                    </FadeSwitchWrapper>

                    <FadeSwitchWrapper switchKey={status}>
                      {status === 'queued' &&
                        <Column themePadding={[theme.space(20), theme.space(0), theme.space(0)]}>
                          <InfoText horizontalAlign="center" themeFont={theme.fonts.q7} themeTextColor={theme.colors.redLight}>
                            <Translated id="app.main.time-info" />
                          </InfoText>
                        </Column>}
                    </FadeSwitchWrapper>

                    <FadeSwitchWrapper switchKey={status}>
                      {(status === 'queued' || status === 'assigned' || status === 'claimed' || status === 'timeout') &&
                        <Row horizontalAlign={status === 'claimed' ? "space-around" : "center"} verticalAlign="top" {...layout.horizontalAlign.stretch}>
                          {(status === 'queued' || status === 'assigned' || status === 'claimed') &&
                            <Column verticalAlign="center" horizontalAlign="center" themePadding={[theme.space(status === 'assigned' ? 12 : 20), theme.space(0), theme.space(0)]}>
                              <Info themeFont={innerWidth > 768 ? theme.fonts.q6 : theme.fonts.q7} themeTextColor={theme.colors.blackLight}>
                                <Translated id="app.main.position" />
                              </Info>
                              <Spacing themeSpace={theme.space(8)} />
                              <Text transform="uppercase" themeFont={theme.fonts.q2} themeTextColor={theme.colors.blackLight}>
                                {status === 'assigned' || status === 'claimed'
                                  ? <Translated id="app.main.now" />
                                  : requestPosition}
                              </Text>
                            </Column>}

                          {(status === 'claimed' || status === 'timeout') &&
                            <Column horizontalAlign="center" verticalAlign="center" themePadding={[theme.space(20), theme.space(0), theme.space(0)]}>
                              <Text themeFont={innerWidth > 768 ? theme.fonts.q6 : theme.fonts.q7} themeTextColor={theme.colors.blackLight} horizontalAlign="center">
                                <Translated id="app.main.verification-time" />
                              </Text>
                              <Spacing themeSpace={theme.space(8)} />
                              <Countdown
                                date={claimTimestamp}
                                onComplete={onTimeout}
                                renderer={({ minutes, seconds }) => (
                                  <Text themeFont={theme.fonts.q2} themeTextColor={theme.colors.blackLight} horizontalAlign="center">
                                    {zeroPad(minutes)}:{zeroPad(seconds)}
                                  </Text>
                                )} />
                            </Column>}
                        </Row>}
                    </FadeSwitchWrapper>
                  </StatusWrapper>
                </GetVerifiedBase>
              </SectionWrapper>}

            {(status === 'verifying' || status === 'verified') &&
              <VerifiedBase verticalAlign="center" horizontalAlign="center">
                <FadeSwitchWrapper switchKey={status === 'verified'}>
                  {status === 'verified' && <>
                    <VerifiedCheckedIcon {...innerWidth <= 768 && { size: 40 }} />
                    <Spacing themeSpace={theme.space(20)} />
                    <Title transform='uppercase' horizontalAlign='center' themeFont={innerWidth > 768 ? theme.fonts.q4 : theme.fonts.q3.override({ size: theme.space(32), lineHeight: theme.space(40) })} themeTextColor={theme.colors.blackLight}>
                      <Translated id="app.main.verified" />
                    </Title>
                    <Spacing themeSpace={theme.space(20)} />
                  </>}
                </FadeSwitchWrapper>

                <RectangleWrapper>
                  <BackgroundRectangle />
                  <Wrapper verticalAlign="center" horizontalAlign="center">
                    <FadeSwitchWrapper switchKey={status === 'verified'}>
                      {status === 'verifying' ? <>
                        <ButtonLoader />
                        <Spacing themeSpace={theme.space(10)} />
                        <Title horizontalAlign="center" themeFont={theme.fonts.q1} themeTextColor={theme.colors.blackLight}>
                          <Translated id="app.main.magic" />
                        </Title>
                        <Spacing themeSpace={theme.space(8)} />
                        <Info themeFont={theme.fonts.q6} themeTextColor={theme.colors.blackLight} horizontalAlign="center">
                          <Translated id="app.main.verifying" />
                        </Info>
                      </> : <>
                        <Info horizontalAlign="center" themeFont={theme.fonts.q6} themeTextColor={theme.colors.blackLight}>
                          <Translated id="app.main.verified-success" />
                        </Info>
                        <Spacing themeSpace={theme.space(8)} />
                        <Title horizontalAlign="center" themeFont={innerWidth > 768 ? theme.fonts.q1 : theme.fonts.q1.override({ size: theme.space(26), lineHeight: theme.space(32) })} themeTextColor={theme.colors.blackLight}>
                          {identificationTimestamp?.toLocaleString()}
                        </Title>
                        <Spacing themeSpace={theme.space(20)} />

                        <Pressable onClick={onInitializeIdentification}>
                          <VerifyButton>
                            <Text
                              horizontalAlign='center'
                              transform='uppercase'
                              themeFont={theme.fonts.q3}
                              themeTextColor={theme.colors.blackLight}
                            >
                              <Translated id="app.main.verify-again" />
                            </Text>
                          </VerifyButton>
                        </Pressable>

                        <Spacing themeSpace={theme.space(12)} />
                        <Text themeFont={theme.fonts.q7} themeTextColor={theme.colors.blackLight} horizontalAlign="center">
                          <Translated id="app.main.verify-again-info" />
                        </Text>
                      </>}
                    </FadeSwitchWrapper>
                  </Wrapper>
                </RectangleWrapper>

                <FadeSwitchWrapper switchKey={status === 'verified'}>
                  {status === 'verified' && <>
                    <Spacing themeSpace={theme.space(20)} />
                    <VerifiedDottedLine size={647} />
                    <Spacing themeSpace={theme.space(20)} />
                    <Title horizontalAlign="center" themeFont={innerWidth > 768 ? theme.fonts.q5 : theme.fonts.q6} themeTextColor={theme.colors.blackLight}>
                      <Translated id="app.main.web3" />
                    </Title>

                    <Spacing themeSpace={theme.space(20)} />

                    <AirDrop verticalAlign="center" horizontalAlign="center">
                      <Info themeFont={theme.fonts.q2} themeTextColor={theme.colors.blackLight} transform="uppercase">
                        <Translated id="app.main.airdrop" />
                      </Info>
                      <Spacing themeSpace={theme.space(12)} />
                      <Row onClick={onTryDemo}>
                        <CornerBorderButton size={138}>
                          <Text themeFont={innerWidth > 768 ? theme.fonts.q3 : theme.fonts.q3.override({ size: theme.space(12), lineHeight: theme.space(18) })} themeTextColor={theme.colors.grayLight} transform="uppercase" horizontalAlign="center">
                            <Translated id="app.main.try-demo" />
                          </Text>
                        </CornerBorderButton>
                      </Row>
                    </AirDrop>
                  </>}
                </FadeSwitchWrapper>
              </VerifiedBase>}
          </FadeSwitchWrapper>
        </Column>
      </Scrollable>

      <InfoModal
        modalRef={infoModalRef}
        onClose={onCloseInfoModal} />

      {innerWidth > 768
        ? <WalletsModalDesktop
          modalRef={connectWalletModalRef}
          connectors={connectors}
          onClose={closeConnectWalletModal}
          onConnect={onConnect}
        />
        : <WalletsMobileModal
          modalRef={connectWalletModalRef}
          connectors={connectors}
          onClose={closeConnectWalletModal}
          onConnect={onConnect}
        />}

      {innerWidth <= 768 &&
        <ChainsModal
          modalRef={chainsModalRef}
          chains={chains}
          selected={currentChain}
          onClose={closeChainsModal}
          onSelect={_onSelectChain}
        />}

      <MetamaskModal
        modalRef={metamaskModalRef}
        onInstall={onInstallMetamask}
        onClose={onCloseMetamaskModal}
        onTryAgain={onMetamaskTryAgain}
      />

      <ErrorModal
        innerWidth={innerWidth}
        modalRef={errorModalRef}
        onTryAgain={onErrorTryAgain}
      />

      <SupportLink href="https://t.me/Unika_support" target="_blank" rel="noreferrer">
        <Row horizontalAlign="center" verticalAlign="center">
          <Support />
          {innerWidth > 768 && <>
            <Spacing themeSpace={theme.space(8)} />
            <Info transform='uppercase'
              themeFont={theme.fonts.q3}
              themeTextColor={theme.colors.grayAlt}>
              <Translated id="app.main.support" />
            </Info>
          </>}
        </Row>
      </SupportLink>

      <Cookie />
    </HeadedView>
  )
});
