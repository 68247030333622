import { FC, useMemo, memo, useState, useEffect, useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { WagmiConfig } from 'wagmi';
import {
  StoreProvider,
  SnackServiceProvider,
  NavigationServiceProvider,
  useDeviceHeight,
  space,
  Layout,
  useSnackService,
} from '@spatium/wallet-kit';

import { Locale } from 'store/local';
import { ApplicationAction, applicationReducer, ApplicationState, useSelector } from 'store';
import { useWalletConnectorService, WalletConnectorServiceProvider } from 'services/wallet-connector';

import '@formatjs/intl-locale/polyfill';

import en from 'lang/en-us';
import ru from 'lang/ru';

import { Theme, Mode, Size } from 'theme';
import { SnackSwitchWrapper } from 'components/snack-switch-wrapper';
import { Snack } from 'components/snack';

import { AppRouting } from './app.routing';
import { GlobalStyle, ModalRoot, SnackRoot } from './app.styles';
import { DefaultColors, DefaultFonts, DefaultScales, DefaultShadows, DefaultSizes, DefaultSpaces } from './app.theme';

export const ThemeDataProvider: FC<{
  mode: Mode; size: Size;
}> = memo(({ mode, size, children }) => {
  const theme = useMemo<Theme>(() => ({
    mode,
    size,
    keyboard: false,
    keyboardSize: space(0),
    layout: {} as Layout,
    colors: DefaultColors[mode],
    scales: DefaultScales,
    sizes: DefaultSizes,
    fonts: DefaultFonts,
    spaces: DefaultSpaces[size],
    shadows: DefaultShadows,
  }), [mode, size]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
});

export const ThemeModeProvider: FC<{ mode: Mode }> = memo(({ mode, children }) => {
  const height = useDeviceHeight();
  const size = height > 750 ? 'large' : height > 670 ? 'medium' : 'small';

  return <ThemeDataProvider mode={mode} size={size}>{children}</ThemeDataProvider>;
});

export const IntlLocaleProvider: FC<{ locale: Locale }> = ({ locale, children }) => {
  const translation = useMemo(() => ({
    'en-us': en,
    'ru': ru,
  } as const), []);

  return <IntlProvider locale={locale} messages={translation[locale]}>{children}</IntlProvider>;
};

const ServiceProvider: FC<{}> = ({ children }) => {
  const locale = useSelector((state) => state.local.locale);
  const mode = useSelector((state) => state.local.mode);

  return (
    <IntlLocaleProvider locale={locale}>
      <ThemeModeProvider mode={mode}>
        <SnackServiceProvider>
          <WalletConnectorServiceProvider>
            <GlobalStyle />
            {children}
          </WalletConnectorServiceProvider>
        </SnackServiceProvider>
      </ThemeModeProvider>
    </IntlLocaleProvider>
  );
};

const WalletConnectorConfigProvider: FC<{}> = ({ children }) => {
  const { wagmiClient } = useWalletConnectorService();

  return (
    <WagmiConfig client={wagmiClient}>
      {children}
    </WagmiConfig>
  );
};

const SnackContainer: FC<{}> = memo(() => {
  const [_snackData] = useSnackService();
  const [snackData, setSnackData] = useState(_snackData);

  useEffect(() => {
    setSnackData(_snackData);
  }, [_snackData])

  const onClose = useCallback(() => {
    setSnackData(null);
  }, []);

  return (
    <SnackSwitchWrapper switchKey={snackData?.id ?? null}>
      {snackData && (
        <Snack keyValue={snackData.key} values={snackData.values} onClose={onClose} />
      )}
    </SnackSwitchWrapper>
  );
});

export const App: FC<{}> = () => (
  <StoreProvider<ApplicationState, ApplicationAction> tag="defaultState" reducer={applicationReducer}>
    <ServiceProvider>
      <WalletConnectorConfigProvider>
        <BrowserRouter>
          <NavigationServiceProvider>
            <AppRouting />
            <SnackRoot><SnackContainer /></SnackRoot>
            <ModalRoot id="modal-root" />
          </NavigationServiceProvider>
        </BrowserRouter>
      </WalletConnectorConfigProvider>
    </ServiceProvider>
  </StoreProvider>
);
