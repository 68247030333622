import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type DottedLineProps = {
  size?: number;
};

export const DottedLine: FC<DottedLineProps & ClassNameProps> = memo(({
  size = 466,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size / size} viewBox="0 0 466 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0.5" y1="0.5" x2="465.5" y2="0.5" stroke="#A2C0CD" strokeLinecap="round" strokeDasharray="8 8" />
  </svg>
));
