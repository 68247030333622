import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type OptimismProps = {
  size?: number;
};

export const Optimism: FC<OptimismProps & ClassNameProps> = memo(({
  size = 20,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2041_7005)">
      <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#FF0420" />
      <path d="M7.08403 12.6579C6.48803 12.6579 6.00003 12.5179 5.62003 12.2379C5.24403 11.9539 5.05603 11.5459 5.05603 11.0219C5.05603 10.9099 5.06803 10.7779 5.09203 10.6179C5.15603 10.2579 5.24803 9.82591 5.36803 9.31791C5.70803 7.94191 6.58803 7.25391 8.00403 7.25391C8.38803 7.25391 8.73603 7.31791 9.04003 7.44991C9.34403 7.57391 9.58403 7.76591 9.76003 8.02191C9.93603 8.27391 10.024 8.57391 10.024 8.92191C10.024 9.02591 10.012 9.15791 9.98803 9.31791C9.91203 9.76191 9.82403 10.1979 9.71603 10.6179C9.54003 11.3019 9.24003 11.8179 8.80803 12.1579C8.38003 12.4939 7.80403 12.6579 7.08403 12.6579ZM7.19203 11.5779C7.47203 11.5779 7.70803 11.4939 7.90403 11.3299C8.10403 11.1659 8.24803 10.9139 8.33203 10.5699C8.44803 10.0979 8.53603 9.68991 8.59603 9.33791C8.61603 9.23391 8.62803 9.12591 8.62803 9.01391C8.62803 8.55791 8.39203 8.32991 7.91603 8.32991C7.63603 8.32991 7.39603 8.41391 7.19603 8.57791C7.00003 8.74191 6.86003 8.99391 6.77603 9.33791C6.68403 9.67391 6.59603 10.0819 6.50403 10.5699C6.48403 10.6699 6.47203 10.7739 6.47203 10.8859C6.46803 11.3499 6.71203 11.5779 7.19203 11.5779Z" fill="white" />
      <path d="M10.372 12.5841C10.316 12.5841 10.276 12.5681 10.244 12.5321C10.22 12.4921 10.212 12.4481 10.22 12.3961L11.256 7.51612C11.264 7.46012 11.292 7.41613 11.34 7.38013C11.384 7.34413 11.432 7.32812 11.484 7.32812H13.48C14.036 7.32812 14.48 7.44413 14.816 7.67213C15.156 7.90413 15.328 8.23613 15.328 8.67213C15.328 8.79613 15.312 8.92813 15.284 9.06413C15.16 9.64013 14.908 10.0641 14.524 10.3401C14.148 10.6161 13.632 10.7521 12.976 10.7521H11.964L11.62 12.3961C11.608 12.4521 11.584 12.4961 11.536 12.5321C11.492 12.5681 11.444 12.5841 11.392 12.5841H10.372ZM13.028 9.71613C13.24 9.71613 13.42 9.66013 13.576 9.54413C13.736 9.42813 13.84 9.26413 13.892 9.04813C13.908 8.96413 13.916 8.88813 13.916 8.82413C13.916 8.68013 13.872 8.56813 13.788 8.49213C13.704 8.41213 13.556 8.37213 13.352 8.37213H12.452L12.168 9.71613H13.028Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2041_7005">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
));
