import { FC, memo } from 'react';

import { Column, Row, theme, View, Text } from 'theme';

export type TestViewProps = {
  connectWallet: () => void;
  setAirdropUnikaAddressToMedium: () => void;
  setAirdropUnikaAddressToRelay: () => void;
  getIdentifications: () => void;
  getIdentificationTimestamp: () => void;
  getUNodeWhiteList: () => void;
  getAirdropWhitelistAddresses: () => void;
  getInteractiveStageRequests: () => void;
  getVerificationRequests: () => void;
  getIdentificationActiveRequests: () => void;
  getVerificationActiveRequests: () => void;
  initializeIdentification: () => void;
  initializeIdentificationRelay: () => void;
  transmitIdentificationRequest: () => void;
  initializeClaimRequest: () => void;
  initializeVerification: () => void;
  initializeVerificationRelay: () => void;
  transmitVerificationRequest: () => void;
  claimInteractiveStageRequest: () => void;
  claimVerificationRequest: () => void;
  finalizeInteractiveStageRequest: () => void;
  finalizeVerificationRequest: () => void;
  transmitVerificationResult: () => void;
  checkInteractiveStageRequests: () => void;
  checkVerificationRequests: () => void;
  checkIdentificationActiveRequests: () => void;
  checkVerificationActiveRequests: () => void;
  checkIfAddressExists: () => void;
  // startIdentification: () => void;
};

export const TestView: FC<TestViewProps> = memo(({
  connectWallet,
  setAirdropUnikaAddressToMedium,
  setAirdropUnikaAddressToRelay,
  getIdentifications,
  getIdentificationTimestamp,
  getUNodeWhiteList,
  getAirdropWhitelistAddresses,
  getInteractiveStageRequests,
  getVerificationRequests,
  getIdentificationActiveRequests,
  getVerificationActiveRequests,
  initializeIdentification,
  initializeIdentificationRelay,
  transmitIdentificationRequest,
  initializeClaimRequest,
  initializeVerification,
  initializeVerificationRelay,
  transmitVerificationRequest,
  claimInteractiveStageRequest,
  claimVerificationRequest,
  finalizeInteractiveStageRequest,
  finalizeVerificationRequest,
  transmitVerificationResult,
  checkInteractiveStageRequests,
  checkVerificationRequests,
  checkIdentificationActiveRequests,
  checkVerificationActiveRequests,
  checkIfAddressExists,
  // startIdentification,
}) => {
  return (
    <View>
      <Column verticalAlign='center' horizontalAlign='center'>
        <Row horizontalAlign='center' themeSpacing={theme.space(50)}>
          <Column horizontalAlign='center' themeSpacing={theme.space(10)}>
            <Text themeFont={theme.fonts.q9.override({ weight: 600 })}>
              Client
            </Text>

            <button onClick={connectWallet}>Connect wallet</button>
            <button onClick={getIdentifications}>Get identifications</button>
            <button onClick={getIdentificationTimestamp}>Get identification timestamp</button>
            <button onClick={getInteractiveStageRequests}>Get interactive stage requests</button>
            <button onClick={getVerificationRequests}>Get verification requests</button>
            <button onClick={getIdentificationActiveRequests}>Get active identification requests</button>
            <button onClick={getVerificationActiveRequests}>Get active verification requests</button>
            <button onClick={initializeIdentification}>Initialize identification</button>
            <button onClick={initializeIdentificationRelay}>Initialize Relay identification</button>
            <button onClick={transmitIdentificationRequest}>Transmit identification request</button>
            <button onClick={initializeVerification}>Initialize verification</button>
            <button onClick={initializeVerificationRelay}>Initialize Relay verification</button>
            <button onClick={transmitVerificationRequest}>Transmit verification request</button>
            {/* <button onClick={startIdentification}>Start identification</button> */}
          </Column>

          <Column horizontalAlign='center' themeSpacing={theme.space(10)}>
            <Text themeFont={theme.fonts.q9.override({ weight: 600 })}>
              Validator
            </Text>

            <button onClick={getUNodeWhiteList}>Get uNode whiteList</button>
            <button onClick={claimInteractiveStageRequest}>Claim interactive stage request</button>
            <button onClick={claimVerificationRequest}>Claim verification request</button>
            <button onClick={finalizeInteractiveStageRequest}>Finilize interactive stage request</button>
            <button onClick={finalizeVerificationRequest}>Finilize verification request</button>
            <button onClick={transmitVerificationResult}>Transmit verification result</button>
            <button onClick={checkInteractiveStageRequests}>Check interactive stage requests</button>
            <button onClick={checkVerificationRequests}>Check verification requests</button>
            <button onClick={checkIdentificationActiveRequests}>Check identification active requests</button>
            <button onClick={checkVerificationActiveRequests}>Check verification active requests</button>
          </Column>

          <Column horizontalAlign='center' themeSpacing={theme.space(10)}>
            <Text themeFont={theme.fonts.q9.override({ weight: 600 })}>
              Airdrop
            </Text>
            <button onClick={getAirdropWhitelistAddresses}>Get Airdrop whiteList</button>
            <button onClick={checkIfAddressExists}>Check if address in whiteList</button>
            <button onClick={initializeClaimRequest}>Initialize claim</button>
            <button onClick={setAirdropUnikaAddressToMedium}>Set Airdrop Unika address to Medium</button>
            <button onClick={setAirdropUnikaAddressToRelay}>Set Airdrop Unika address to Relay</button>
          </Column>
        </Row>
      </Column>
    </View>
  );
});
