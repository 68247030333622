import styled, { css } from 'styled-components';
import { Text, Column, Row, theme, Button, Scrollable as ScrollableBase } from 'theme';
import { DropdownArrow } from 'assets/dropdown-arrow';

export const Scrollable = styled(ScrollableBase)`
  & > *:first-child {
    margin-top: auto;
  }

  & > *:first-child {
    margin-bottom: auto;
  }
`;

export const Title = styled(Text)`
  letter-spacing: 0.1em;
`;

export const Info = styled(Text)`
  letter-spacing: 0.05em;
`;

export const ModalWrapper = styled(Column)`
  width: 740px;
  padding: 80px;
  background: ${theme.colors.background};
  border: 1px solid ${theme.colors.primary};
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 32px 16px;

    ${Title}, ${Info} {
      width: 100%;
    }
  }
`;

export const SecondaryButton = styled(Button)`
  width: 100%;
  height: 44px;

  background: linear-gradient(259.18deg, #05D2FF -20.75%, #2BF5FF 34.12%, #04F8CA 88.11%);
  border-radius: 5px;
`

export const ModalButtonWrapper = styled(Row)`
    @media screen and (max-width: 540px) {
        flex-direction: column;
    }
`;

export const Close = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 40px;
    right: 40px;
    background: linear-gradient(259.18deg, #03E2DA -20.75%, #04F8CA 112.15%);
    border-radius: 10px;
    cursor: pointer;

    &::before, &::after {
        content: "";
        width: 28.77px;
        height: 1.92px;
        position: absolute;
        background-color: ${theme.colors.foreground};
    }
    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }

    @media screen and (max-width: 768px) {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 32px;
        right: 16px;
        background: ${theme.colors.grayLight};
        border-radius: 5px;
        box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.35), inset 0px 7px 11px -4px rgba(255, 255, 255, 0.4), inset 0px -82px 68px -64px rgba(255, 255, 255, 0.25), inset 0px 98px 100px -48px rgba(255, 255, 255, 0.1), inset 0px 4px 18px rgba(255, 255, 255, 0.3), inset 0px 0px 40px rgba(253, 253, 253, 0.6);
        backdrop-filter: blur(50px);

        &::before, &::after {
            content: "";
            width: 22px;
        }
    }
`;

export const LogoWrapper = styled(Column)`
    padding-left: ${theme.spaces.side};
`;

export const BaseTextWrapper = styled(Column)`
    width: 675px;
`;

export const HelpInfo = styled(Info)`
    @media screen and (max-width: 768px) {
        max-width: 344px !important;
    }
`;

export const ToolTip = styled(Text)`
    position: absolute;
    background: ${theme.colors.white};
    border-radius: 10px;
    padding: 12px 16px;
    top: 40px;
    width: 530px;
    transform: translateX(-50%);
    z-index: 10;
    filter: drop-shadow(0px 7px 25px rgba(35, 48, 69, 0.1));
    
    &:before {
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent; 
        border-bottom:8px solid ${theme.colors.white};
        top: -8px;
        left: calc(50% + 2.5px);
    }

    @media screen and (max-width: 768px) {
        max-width: 344px;
        transform: translateX(-68%);

        &:before {
            left: 68%;
        }
    }
`;

export const IconWrapper = styled.div`
    position: relative;
    display: inline-block;
    margin-left: 5px;
    transition: all .2s;
    cursor: pointer;
    padding-bottom: 10px;

    svg {
        path {
            transition: all .2s;
        }
    }

    &:hover {
        svg {
            path {
                fill: #04F8CA;
            }
        }
        ${ToolTip} {
            display: block;
        }
    }
`;

export const GetVerifiedBase = styled(Column)`
    width: 466px;
`;

export const SectionWrapper = styled(Column)`
    @media screen and (max-width: 768px) {
        padding-bottom: 40px;
    }
`;

export const InfoText = styled(Text)`
    padding: 10px 50px;
    background: ${theme.colors.redVeryLight};
    border-radius: 4px;
`;

export const VerifiedBase = styled(Column)`
    max-width: 675px;
    
    @media screen and (max-width: 768px) {
        padding-bottom: 40px;
        max-width: 688px;
    }
`;

export const RectangleWrapper = styled.div`
    width: 100%;
    height: 250px;
    position: relative;

    @media screen and (max-width: 768px) {
        height: 297px;
    }
`;

export const StatusWrapper = styled(Column)`
  height: 150px;
`;

export const Wrapper = styled(Column)`
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 540px) {
        width: 90%;
    }
`;

export const AirDrop = styled(Column)`
    padding: 12px;
    border: 1px solid ${theme.colors.grayLight};
    border-radius: 10px;
    background: ${theme.colors.white};
`;

export const Verified = styled(Row)`
    svg {
        cursor: pointer;
    }
`;

export const SupportLink = styled.a`
    width: 158px !important;
    height: 48px;
    position: fixed;
    right: 30px;
    bottom: 30px;
    background: ${theme.colors.white};
    box-shadow: 0px 7px 25px rgba(35, 48, 69, 0.05);
    border-radius: 40px;
    text-decoration: none;

    ${Row} {
        height: 100%;
    }

    @media screen and (max-width: 768px) {
        width: 56px  !important;
        height: 56px;
        border-radius: 50%;
    }

    @media screen and (min-width: 768px) {
        ${Info} {
        transition: all 0.3s linear;
        }

        svg {
            path {
                transition: all 0.3s linear;
            }
        }
    
        &:hover {
            ${Info} {
                color: ${theme.colors.primary};
            }

            svg {
                path {
                    fill: ${theme.colors.primary};
                }
            }
        }
    }
`;

export const Link = styled.a`
    color: ${theme.colors.blackLight};
`;

export const BottomModalWrapper = styled(ModalWrapper)`
    @media screen and (max-width: 768px) {
        padding: 32px 16px 50px;
        border-radius: 10px 10px 0 0;
        border-bottom-color: transparent;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

export const ChainsWrapper = styled.div`
    position: relative;
`;

export const SelectedChain = styled(Row) <{ dropdown: boolean }>`
    cursor: pointer;
    padding: 5px 12px;
    border: 1px solid ${theme.colors.background};
    transition: all .2s ease-in-out;

    ${({ dropdown }) => dropdown && css`
        background: ${theme.colors.white};
        border: 1px solid ${theme.colors.grayLight};
        border-radius: 5px;
    `}

    @media screen and (min-width: 768px) {
        &:hover {
            background: ${theme.colors.white};
            border: 1px solid ${theme.colors.grayLight};
            border-radius: 5px;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 0 8px;
        border-right: 1px solid ${theme.colors.grayLight};
    }
`;

export const Arrow = styled(DropdownArrow) <{ down: boolean }>`
    transition: transform .2s ease-in-out;
    transform: rotate(${({ down }) => down ? '-180deg' : '0'});
`;

export const ChainDropdown = styled.ul`
    min-width: 223px;
    list-style: none;
    margin: 0;
    padding:11px 8px;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;

    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.grayLight};
    box-shadow: 0px 7px 25px rgba(35, 48, 69, 0.1);
    border-radius: 5px;
`;

export const ChainItem = styled.li<{ active: boolean }>`
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;

    ${({ active }) => active && css`
        background: ${theme.colors.grayLight};
        border-radius: 5px;
    `}

    &:hover {
        background: ${theme.colors.grayLight};
        border-radius: 5px;
    }
`;

export const ChainsListModal = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
`;

export const ConnectWalletModalWrapper = styled(ModalWrapper)`
  width: 756px;
`;

export const ConnectWalletWrapper = styled(Row)`
    width: 100%;

    @media screen and (max-width: 768px) {
        flex-direction:  column;
    }
`;

export const ConnectWalletItem = styled(Column)`
    width: 188px;
    height: 128px;
    border: 1px solid ${theme.colors.grayLight};
    border-radius: 10px;
    transition: all .2s ease-in-out;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 7px 25px rgba(35, 48, 69, 0.1);
    }

    @media screen and (max-width: 768px) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 61px;

        ${Info} {
            width: auto;
        }
    }
`;
