import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

import { CornerBorderButtonBase, ConnectText } from './corner-border-button.styles';

import { ConnectWallet } from 'assets/connect-wallet';

export type CornerBorderButtonProps = {
  size?: number;
  disabled?: boolean;
};

export const CornerBorderButton: FC<CornerBorderButtonProps & ClassNameProps> = memo(({ className, style, children, size, disabled }) => (
  <CornerBorderButtonBase className={className} style={style} disabled={disabled}>
    <ConnectWallet size={size} />
    <ConnectText>{children}</ConnectText>
  </CornerBorderButtonBase>
));
