import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type AttentionIconProps = {
  size?: number;
};

export const AttentionIcon: FC<AttentionIconProps & ClassNameProps> = memo(({
  size = 64,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.6667 34.6654H29.3333V18.6654H34.6667M34.6667 45.332H29.3333V39.9987H34.6667M32 5.33203C28.4981 5.33203 25.0305 6.02179 21.7951 7.36191C18.5598 8.70204 15.6201 10.6663 13.1438 13.1425C8.14286 18.1435 5.33334 24.9263 5.33334 31.9987C5.33334 39.0711 8.14286 45.8539 13.1438 50.8549C15.6201 53.3311 18.5598 55.2954 21.7951 56.6355C25.0305 57.9756 28.4981 58.6654 32 58.6654C39.0725 58.6654 45.8552 55.8559 50.8562 50.8549C55.8572 45.8539 58.6667 39.0711 58.6667 31.9987C58.6667 28.4968 57.9769 25.0292 56.6368 21.7938C55.2967 18.5585 53.3324 15.6187 50.8562 13.1425C48.38 10.6663 45.4403 8.70204 42.2049 7.36191C38.9696 6.02179 35.5019 5.33203 32 5.33203Z" fill="#F82F2F" />
  </svg>
));
