import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type BackgroundRectangleProps = {
  size?: number;
};

export const BackgroundRectangle: FC<BackgroundRectangleProps & ClassNameProps> = memo(({
  className,
  style,
}) => (
  <svg className={className} style={style} width="100%" height="100%" viewBox="0 0 675 250" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
    <path opacity="0.4" d="M310 30H643V20.81C643 19.6595 642.505 18.5647 641.64 17.8052L631.614 8.99524C630.884 8.35378 629.946 8 628.974 8H310V30Z" fill="#A2C0CD" />
    <path d="M18.6708 245.6L8.48436 237.428C3.75296 233.632 1 227.893 1 221.828V62.764C1 56.9898 3.49559 51.4974 7.84492 47.6994L55.6714 5.93533C59.3152 2.75339 63.9888 1 68.8264 1H299.75C304.398 1 308.902 2.61917 312.486 5.57924L330.117 20.1409C333.701 23.101 338.205 24.7202 342.853 24.7202H652.708C653.888 24.7202 655.042 25.0681 656.025 25.7204L672.317 36.529C673.993 37.6407 675 39.5179 675 41.5288V62.927V205.485C675 207.353 674.13 209.115 672.647 210.25L622.323 248.765C621.277 249.566 619.995 250 618.677 250H31.1864C26.6354 250 22.2205 248.448 18.6708 245.6Z" fill="white" />
    <path d="M657.696 207.23L623.202 235.273C621.741 236.461 622.581 238.825 624.464 238.825H658.958C660.063 238.825 660.958 237.929 660.958 236.825V208.782C660.958 207.097 659.003 206.167 657.696 207.23Z" stroke="#A2C0CD" />
    <path d="M4.26163 32.5945L38.7561 4.5512C40.2169 3.36357 39.3771 0.99934 37.4944 0.99934H3C1.89543 0.99934 1 1.89478 1 2.99934V31.0426C1 32.7269 2.9547 33.657 4.26163 32.5945Z" stroke="#A2C0CD" />
  </svg>
));
