import styled from 'styled-components';
import { Button, Text } from 'theme';
import { ButtonInitial as BtnInitial } from 'assets/button-initial';
import { ButtonHover as BtnHover } from 'assets/button-hover';

export const VerifyButtonBase = styled(Button)`
    position: relative;
    width: 240px;
    height: 60px;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
    }
`;

export const ButtonHover = styled(BtnHover)`
    position: absolute;
    top: -10px;
    left: -10px;
`;

export const ButtonInitial = styled(BtnInitial)`
    position: relative;
`;

export const TextWrapper = styled.div`
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
`;

export const Loader = styled.div`
    position: absolute;
    top: 50%;
    left: 32px;
    transform: translateY(-50%);
`;

export const Number = styled(Text)`
    position: absolute;
    bottom: -4px;
    left: 8%;
    letter-spacing: 0.1em;

    @media screen and (max-width: 540px) {
        left: -13%;
    }
`;
