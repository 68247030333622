import { useState, useEffect, useCallback } from 'react';
import { ethers, BigNumber } from 'ethers';
import { screen } from '@spatium/wallet-kit';

import { hexToString } from 'lib/utils';

import contractAddress from '../../contracts/contract-address.json';
import {
  UnikaValidator,
  UnikaValidator__factory,
  UnikaIdentification,
  UnikaIdentification__factory,
  UnikaIdentificationRelay,
  UnikaIdentificationRelay__factory,
  UnikaMedium,
  UnikaMedium__factory,
  UnikaMediumRelay,
  UnikaMediumRelay__factory,
  AirdropServiceExample__factory,
  AirdropServiceExample,
} from '../../contracts/typechain-types';

import { TestProps } from './test.props';
import { TestView } from './test.view';

// const chainId = 31337; // hardhat
const chainId = 10200; // gnosis testnet
// const chainId = 5; // ethereum testnet
// const chainId = 420 // optimism testnet
// const chainId = 421613 // arbitrum testnet
// const chainId = 80001 // polygon testnet
// const chainId = 280 // zksync testnet
// const chainId = 43113 // avalanche testnet
// const chainId = 97 // binance testnet
// const chainId = 4002 // fantom testnet
// const chainId = 1313161555 // aurora testnet
// const chainId = 44787 // celo testnet
// const chainId = 123 // fuse testnet

enum IdentificationResult {
  __,
  ERROR,
  FAILED,
  PASSED
}

enum VerificationResult {
  __,
  ERROR,
  IDENTIFICATION_NOT_FOUND,
  CONFIRM,
  REJECT
}

enum Operation {
  __,
  NONE_IN_LIST,
  ALL_IN_LIST
}

export const Test = screen<TestProps>(() => {
  const [account, setAccount] = useState<string>();

  const [ValidatorsContract, setValidatorsContract] = useState<UnikaValidator>();
  const [IdentificationContract, setIdentificationContract] = useState<UnikaIdentification>();
  const [IdentificationRelayContract, setIdentificationRelayContract] = useState<UnikaIdentificationRelay>();
  const [MediumContract, setMediumContract] = useState<UnikaMedium>();
  const [MediumRelayContract, setMediumRelayContract] = useState<UnikaMediumRelay>();
  const [AirdropServiceContract, setAirdropServiceContract] = useState<AirdropServiceExample>();

  useEffect(() => {
    try {
      if (!window.ethereum) {
        throw Error('metamask-not-installed');
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum as any);
      const signer = provider.getSigner();

      const validators = UnikaValidator__factory.connect(contractAddress.UnikaValidator, signer);
      setValidatorsContract(validators);

      const identification = UnikaIdentification__factory.connect(contractAddress.UnikaIdentification, signer);
      setIdentificationContract(identification);

      const identificationRelay = UnikaIdentificationRelay__factory.connect(contractAddress.UnikaIdentificationRelay, signer);
      setIdentificationRelayContract(identificationRelay);

      const medium = UnikaMedium__factory.connect(contractAddress.UnikaMedium, signer);
      setMediumContract(medium);

      const mediumRelay = UnikaMediumRelay__factory.connect(contractAddress.UnikaMediumRelay, signer);
      setMediumRelayContract(mediumRelay);

      const serviceExample = AirdropServiceExample__factory.connect(contractAddress.AirdropServiceExample, signer);
      setAirdropServiceContract(serviceExample);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (!account || !ValidatorsContract) {
      return;
    }

    try {
      ValidatorsContract.on('UNodeActiveSlotsUpdated', (address_: string, activeSlots: BigNumber) => {
        console.log('UNodeActiveSlotsUpdated', address_, activeSlots);
      });
    } catch (e) {
      console.error(e);
    }

    return () => {
      ValidatorsContract.removeAllListeners();
    };
  }, [account, ValidatorsContract]);

  useEffect(() => {
    if (!account || !IdentificationContract) {
      return;
    }

    try {
      IdentificationContract.on('InteractiveStageRequestAdded', (requestId: BigNumber, clientAddress: string) => {
        console.log('InteractiveStageRequestAdded', requestId, clientAddress);
      });

      IdentificationContract.on('InteractiveStageRequestUpdated', (requestId: BigNumber, clientAddress: string) => {
        console.log('InteractiveStageRequestUpdated', requestId, clientAddress);
      });

      IdentificationContract.on('InteractiveStageRequestAssigned', (requestId: BigNumber, clientAddress: string, validatorAddress: string) => {
        console.log('InteractiveStageRequestAssigned', requestId, clientAddress, validatorAddress);
      });

      IdentificationContract.on('InteractiveStageRequestClaimed', (requestId: BigNumber, clientAddress: string, validatorAddress: string) => {
        console.log('InteractiveStageRequestClaimed', requestId, clientAddress, validatorAddress);
      });

      IdentificationContract.on('InteractiveStageRequestResult', (requestId: BigNumber, clientAddress: string, validatorAddress: string, result: IdentificationResult, error?: string) => {
        console.log('InteractiveStageRequestResult', requestId, clientAddress, validatorAddress, result as IdentificationResult, error);
      });

      IdentificationContract.on('IdentificationRequestFinished', (requestId: BigNumber, clientAddress: string, result: IdentificationResult, timestamp: BigNumber) => {
        console.log('IdentificationRequestFinished', requestId, clientAddress, result as IdentificationResult, timestamp.toNumber());
      });

      IdentificationContract.on('IdentificationRequestTimeout', (requestId: BigNumber, clientAddress: string, validatorAddress: string) => {
        console.log('IdentificationRequestTimeout', requestId, clientAddress, validatorAddress);
      });
    } catch (e) {
      console.error(e);
    }

    return () => {
      IdentificationContract.removeAllListeners();
    };
  }, [account, IdentificationContract]);

  useEffect(() => {
    if (!account || !IdentificationRelayContract) {
      return;
    }

    try {
      IdentificationRelayContract.on('IdentificationRelayRequestReceived', (clientAddress: string) => {
        console.log('IdentificationRelayRequestReceived', clientAddress);
      });
    } catch (e) {
      console.error(e);
    }

    return () => {
      IdentificationRelayContract.removeAllListeners();
    };
  }, [account, IdentificationRelayContract]);

  useEffect(() => {
    if (!account || !MediumContract) {
      return;
    }

    try {
      MediumContract.on('VerificationRequestAdded', (requestId: BigNumber, clientAddress: string) => {
        console.log('VerificationRequestAdded', requestId, clientAddress);
      });

      MediumContract.on('VerificationRequestAssigned', (requestId: BigNumber, clientAddress: string, validatorAddress: string) => {
        console.log('VerificationRequestAssigned', requestId, clientAddress, validatorAddress);
      });

      MediumContract.on('VerificationRequestClaimed', (requestId: BigNumber, clientAddress: string, validatorAddress: string) => {
        console.log('VerificationRequestClaimed', requestId, clientAddress, validatorAddress);
      });

      MediumContract.on('VerificationRequestResult', (requestId: BigNumber, clientAddress: string, validatorAddress: string, result: VerificationResult) => {
        console.log('VerificationRequestResult', requestId, clientAddress, validatorAddress, result);
      });

      MediumContract.on('VerificationRequestFinished', (requestId: BigNumber, clientAddress: string, result: VerificationResult, operation: Operation, serviceAddress: string, serviceRequestId: BigNumber, serviceChainId: BigNumber) => {
        console.log('VerificationRequestFinished', requestId, clientAddress, result, operation, serviceAddress, serviceRequestId, serviceChainId);
      });

      MediumContract.on('VerificationRequestTimeout', (requestId: BigNumber, clientAddress: string) => {
        console.log('VerificationRequestTimeout', requestId, clientAddress);
      });
    } catch (e) {
      console.error(e);
    }

    return () => {
      MediumContract.removeAllListeners();
    }
  }, [account, MediumContract]);

  useEffect(() => {
    if (!account || !MediumRelayContract) {
      return;
    }

    try {
      MediumRelayContract.on('MediumRelayRequestReceived', (operation: Operation, serviceAddress: string, serviceRequestId: BigNumber, clientAddress: string, chainid: BigNumber) => {
        console.log('MediumRelayRequestReceived', operation, serviceAddress, serviceRequestId.toNumber(), clientAddress, chainid.toNumber());
      });

      MediumRelayContract.on('MediumRelayRequestFinished', (operation: Operation, serviceAddress: string, serviceRequestId: BigNumber, clientAddress: string, result: VerificationResult, error: string) => {
        console.log('MediumRelayRequestFinished', operation, serviceAddress, serviceRequestId.toNumber(), clientAddress, result, error);
      });
    } catch (e) {
      console.error(e);
    }

    return () => {
      MediumRelayContract.removeAllListeners();
    }
  }, [account, MediumRelayContract]);

  useEffect(() => {
    if (!account || !AirdropServiceContract) {
      return;
    }

    try {
      AirdropServiceContract.on('AirdropClaimRequestAdded', (operation: Operation, requestId: BigNumber, clientAddress: string) => {
        console.log('AirdropClaimRequestAdded', operation, requestId, clientAddress);
      });

      AirdropServiceContract.on('AirdropClaimRequestConfirmed', (requestId: BigNumber, clientAddress: string) => {
        console.log('AirdropClaimRequestConfirmed', requestId, clientAddress);
      });

      AirdropServiceContract.on('AirdropClaimRequestDenied', (requestId: BigNumber, clientAddress: string) => {
        console.log('AirdropClaimRequestDenied', requestId, clientAddress);
      });

      AirdropServiceContract.on('AirdropClaimRequestError', (requestId: BigNumber, clientAddress: string, error: string) => {
        console.log('AirdropClaimRequestError', requestId, clientAddress, error);
      });
    } catch (e) {
      console.error(e);
    }

    return () => {
      AirdropServiceContract.removeAllListeners();
    }
  }, [account, AirdropServiceContract]);

  useEffect(() => {
    if (!window.ethereum) {
      return;
    }

    const chainChanged = () => (_chainId: string) => {
      if (chainId !== parseInt(_chainId, 16)) {
        window.location.reload();
      }
    };

    window.ethereum.on?.('chainChanged', chainChanged);

    const accountsChanged = (accounts: Array<string>) => {
      console.log('account-changed:', accounts);
    }

    window.ethereum.on?.('accountsChanged', accountsChanged as any);

    return () => {
      window.ethereum?.removeListener?.('chainChanged', chainChanged);
      window.ethereum?.removeListener?.('accountsChanged', accountsChanged);
    }
  }, []);

  useEffect(() => {
    console.log('account:', account);
  }, [account])

  const connectWallet = useCallback(async () => {
    try {
      if (!window.ethereum || !window.ethereum.isMetaMask) {
        throw Error('metamask-not-installed');
      }

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${Number(chainId).toString(16)}` }],
      });

      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      setAccount(accounts?.[0]);
    } catch (e: any) {
      console.error(e);
      setAccount(undefined);
    }
  }, []);

  const setAirdropUnikaAddressToMedium = useCallback(async () => {
    try {
      if (!account || !AirdropServiceContract || !MediumContract) {
        throw Error('wallet-not-connected');
      }

      const tx = await AirdropServiceContract.setUnikaAddress(MediumContract.address);
      await tx.wait();

      console.log('set-airdrop-unika-address-to-medium-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, AirdropServiceContract, MediumContract]);

  const setAirdropUnikaAddressToRelay = useCallback(async () => {
    try {
      if (!account || !AirdropServiceContract || !MediumRelayContract) {
        throw Error('wallet-not-connected');
      }

      const tx = await AirdropServiceContract.setUnikaAddress(MediumRelayContract.address);
      await tx.wait();

      console.log('set-airdrop-unika-address-to-relay-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, AirdropServiceContract, MediumRelayContract]);

  const getIdentifications = useCallback(async () => {
    try {
      if (!account || !IdentificationContract) {
        throw Error('wallet-not-connected');
      }
      
      const identifications = await IdentificationContract.getIdentifications();
      console.log('identifications:', identifications);
    } catch (e: any) {
      console.error(e);
    }
  }, [account, IdentificationContract]);

  const getIdentificationTimestamp = useCallback(async () => {
    try {
      if (!account || !IdentificationContract) {
        throw Error('wallet-not-connected');
      }

      const timestamp = await IdentificationContract.getIdentificationTimeByAddress(account);

      if (timestamp.toNumber() === 0) {
        console.log('not-identified');
      } else {
        console.log('identification-timestamp:', new Date(timestamp.toNumber() * 1000).toString());
      }
    } catch (e: any) {
      console.error(e);
    }
  }, [account, IdentificationContract]);

  const getUNodeWhiteList = useCallback(async () => {
    try {
      if (!account || !ValidatorsContract) {
        throw Error('wallet-not-connected');
      }

      const uNodeWhiteList = await ValidatorsContract.getUNodeWhiteList();
      console.log('uNodeWhiteList:', uNodeWhiteList);
    } catch (e: any) {
      console.error(e);
    }
  }, [account, ValidatorsContract]);

  const getAirdropWhitelistAddresses = useCallback(async () => {
    try {
      if (!account || !AirdropServiceContract) {
        throw Error('wallet-not-connected');
      }

      const whiteList = await AirdropServiceContract.getWhitelistAddresses();
      console.log('Airdrop-whiteList:', whiteList);
    } catch (e: any) {
      console.error(e);
    }
  }, [account, AirdropServiceContract]);

  const getInteractiveStageRequests = useCallback(async () => {
    try {
      if (!account || !IdentificationContract) {
        throw Error('wallet-not-connected');
      }

      const interactiveStageRequests = await IdentificationContract.getInteractiveStageRequests();
      console.log('interactiveStageRequests:', interactiveStageRequests);
    } catch (e: any) {
      console.error(e);
    }
  }, [account, IdentificationContract]);

  const getVerificationRequests = useCallback(async () => {
    try {
      if (!account || !MediumContract) {
        throw Error('wallet-not-connected');
      }

      const verificationRequests = await MediumContract.getVerificationRequests();
      console.log('verificationRequests:', verificationRequests);
    } catch (e: any) {
      console.error(e);
    }
  }, [account, MediumContract]);

  const getIdentificationActiveRequests = useCallback(async () => {
    try {
      if (!account || !IdentificationContract) {
        throw Error('wallet-not-connected');
      }

      const activeRequests = await IdentificationContract.getActiveRequests();
      console.log('identification-activeRequests:', activeRequests);
    } catch (e: any) {
      console.error(e);
    }
  }, [account, IdentificationContract]);

  const getVerificationActiveRequests = useCallback(async () => {
    try {
      if (!account || !MediumContract) {
        throw Error('wallet-not-connected');
      }

      const activeRequests = await MediumContract.getActiveRequests();
      console.log('verification-activeRequests:', activeRequests);
    } catch (e: any) {
      console.error(e);
    }
  }, [account, MediumContract]);

  const initializeIdentificationRequest = useCallback(async () => {
    try {
      if (!account || !IdentificationContract) {
        throw Error('wallet-not-connected');
      }

      const tx = await IdentificationContract.initializeIdentification(ethers.constants.AddressZero);
      await tx.wait();

      console.log('initialize-identification-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, IdentificationContract]);

  const initializeIdentificationRequestRelay = useCallback(async () => {
    try {
      if (!account || !IdentificationRelayContract) {
        throw Error('wallet-not-connected');
      }

      const tx = await IdentificationRelayContract.initializeIdentification();
      await tx.wait();

      console.log('initialize-identification-relay-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, IdentificationRelayContract]);

  const transmitIdentificationRequest = useCallback(async () => {
    try {
      if (!account || !IdentificationContract) {
        throw Error('wallet-not-connected');
      }

      const clientAddress = account;

      const tx = await IdentificationContract.initializeIdentification(clientAddress);
      await tx.wait();

      console.log('initialize-identification-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, IdentificationContract]);

  const initializeClaimRequest = useCallback(async () => {
    try {
      if (!account || !AirdropServiceContract) {
        throw Error('wallet-not-connected');
      }

      const tx = await AirdropServiceContract.initializeClaimRequest();
      await tx.wait();

      console.log('initialize-claim-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, AirdropServiceContract]);

  const initializeVerificationRequest = useCallback(async () => {
    try {
      if (!account || !MediumContract || !AirdropServiceContract) {
        throw Error('wallet-not-connected');
      }

      const operation = Operation.NONE_IN_LIST;
      const serviceRequestId = 1;
      const chainId = 0;

      const tx = await MediumContract.initializeVerification(operation, AirdropServiceContract.address, serviceRequestId, ethers.constants.AddressZero, chainId);
      await tx.wait();

      console.log('initialize-verification-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, MediumContract, AirdropServiceContract]);

  const initializeVerificationRequestRelay = useCallback(async () => {
    try {
      if (!account || !MediumRelayContract || !AirdropServiceContract) {
        throw Error('wallet-not-connected');
      }

      const operation = Operation.NONE_IN_LIST;
      const serviceRequestId = 1;

      const tx = await MediumRelayContract.initializeVerification(operation, AirdropServiceContract.address, serviceRequestId);
      await tx.wait();

      console.log('initialize-verification-relay-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, MediumRelayContract, AirdropServiceContract]);

  const transmitVerificationRequest = useCallback(async () => {
    try {
      if (!account || !MediumContract || !AirdropServiceContract) {
        throw Error('wallet-not-connected');
      }

      const operation = Operation.NONE_IN_LIST;
      const serviceRequestId = 1;
      const clientAddress = account;
      const chainId = 10200;

      const tx = await MediumContract.initializeVerification(operation, AirdropServiceContract.address, serviceRequestId, clientAddress, chainId);
      await tx.wait();

      console.log('transmit-verification-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, MediumContract, AirdropServiceContract]);

  const claimInteractiveStageRequest = useCallback(async () => {
    try {
      if (!account || !IdentificationContract) {
        throw Error('wallet-not-connected');
      }

      const requestId = 1;
      const connectionData = { url: 'https://test-bio.svort.me:8001/facetec', nonce: 'test' };

      const tx = await IdentificationContract.claimInteractiveStageRequest(requestId, connectionData);
      await tx.wait();

      console.log('claim-interactive-stage-request-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        // console.error('Error:', e.data?.message?.replace(/(.*reverted with reason string ')(.*)('", method.*)/, '$2'));
      } else {
        console.error(e);
      }
    }
  }, [account, IdentificationContract]);

  const claimVerificationRequest = useCallback(async () => {
    try {
      if (!account || !MediumContract) {
        throw Error('wallet-not-connected');
      }

      const requestId = 1;

      const tx = await MediumContract.claimVerificationRequest(requestId);
      await tx.wait();

      console.log('claim-verification-request-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, MediumContract]);

  const finalizeInteractiveStageRequest = useCallback(async () => {
    try {
      if (!account || !IdentificationContract) {
        throw Error('wallet-not-connected');
      }

      const requestId = 1;
      const result = IdentificationResult.PASSED;
      const error = '';

      const tx = await IdentificationContract.finalizeInteractiveStageRequest(requestId, result, error);
      await tx.wait();

      console.log('finalize-interactive-stage-request-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, IdentificationContract]);

  const finalizeVerificationRequest = useCallback(async () => {
    try {
      if (!account || !MediumContract) {
        throw Error('wallet-not-connected');
      }

      const requestId = 1;
      const result = VerificationResult.CONFIRM;
      const error = '';

      const tx = await MediumContract.finalizeVerificationRequest(requestId, result, error);
      await tx.wait();

      console.log('finalize-verification-request-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, MediumContract]);

  const transmitVerificationResult = useCallback(async () => {
    try {
      if (!account || !MediumRelayContract || !AirdropServiceContract) {
        throw Error('wallet-not-connected');
      }

      const operation = Operation.NONE_IN_LIST;
      const serviceRequestId = 1;
      const clientAddress = account;
      const result = VerificationResult.CONFIRM;
      const error = '';

      const tx = await MediumRelayContract.finalizeVerification(operation, AirdropServiceContract.address, serviceRequestId, clientAddress, result, error);
      await tx.wait();

      console.log('transmit-verification-result-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, MediumRelayContract, AirdropServiceContract]);

  const checkInteractiveStageRequests = useCallback(async () => {
    try {
      if (!account || !IdentificationContract) {
        throw Error('wallet-not-connected');
      }

      const updateRequired = await IdentificationContract.checkInteractiveStageRequestsBeforeUpdate();

      if (updateRequired) {
        const tx = await IdentificationContract.updateInteractiveStageRequests();
        await tx.wait();
      }

      console.log('check-interactive-stage-requests-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, IdentificationContract]);

  const checkVerificationRequests = useCallback(async () => {
    try {
      if (!account || !MediumContract) {
        throw Error('wallet-not-connected');
      }

      const updateRequired = await MediumContract.checkVerificationRequestsBeforeUpdate();

      if (updateRequired) {
        const tx = await MediumContract.updateVerificationRequests();
        await tx.wait();
      }

      console.log('check-verification-requests-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, MediumContract]);

  const checkIdentificationActiveRequests = useCallback(async () => {
    try {
      if (!account || !IdentificationContract) {
        throw Error('wallet-not-connected');
      }

      const updateRequired = await IdentificationContract.checkActiveRequestsBeforeUpdate();

      if (updateRequired) {
        const tx = await IdentificationContract.updateActiveRequests();
        await tx.wait();
      }

      console.log('check-identification-active-requests-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, IdentificationContract]);

  const checkVerificationActiveRequests = useCallback(async () => {
    try {
      if (!account || !MediumContract) {
        throw Error('wallet-not-connected');
      }

      const updateRequired = await MediumContract.checkActiveRequestsBeforeUpdate();

      if (updateRequired) {
        const tx = await MediumContract.updateActiveRequests();
        await tx.wait();
      }

      console.log('check-verification-active-requests-complete');
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        console.error('operation-denied');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
        // } else if (e?.code === 'UNPREDICTABLE_GAS_LIMIT' && e.data?.message?.includes('reverted with reason string')) {
        //   console.error('Error:', e.data?.message);
      } else {
        console.error(e);
      }
    }
  }, [account, MediumContract]);

  const checkIfAddressExists = useCallback(async () => {
    try {
      if (!account || !AirdropServiceContract) {
        throw Error('wallet-not-connected');
      }

      const exists = await AirdropServiceContract.checkIfAddressExists(account);
      console.log('address-in-list:', exists);
    } catch (e: any) {
      console.error(e);
    }
  }, [account, AirdropServiceContract]);

  // type SessionTokenResponse = {
  //   sessionToken: string;
  //   success: boolean;
  //   wasProcessed: boolean;
  //   error: boolean;
  // };

  // type CheckLivenessResponse = {
  //   faceScanSecurityChecks: {
  //     replayCheckSucceeded: boolean;
  //     sessionTokenCheckSucceeded: boolean;
  //     auditTrailVerificationCheckSucceeded: boolean;
  //     faceScanLivenessCheckSucceeded: boolean;
  //   };
  //   ageEstimateGroupEnumInt: number;
  //   retryScreenEnumInt: number;
  //   scanResultBlob: string;
  //   success: boolean;
  //   wasProcessed: boolean;
  //   error: boolean;
  // };

  // const deviceKeyIdentifier = process.env.REACT_APP_DEVICE_KEY_IDENTIFIER ?? ''
  // const publicFaceScanEncryptionKey = process.env.REACT_APP_PUBLIC_FACE_SCAN_ENCRYPTION_KEY ?? '';

  // const { initialize } = useDistortionService();

  // const translation = useMemo(() => ({
  //   'en-us': en,
  //   'ru': ru,
  // }), []);

  // const locale = useSelector((_state) => _state.local.locale);

  // const startIdentification = useCallback(async () => {
  //   const validatorUrl = 'https://test-bio.svort.me:8001/facetec';
  //   let sessionToken;

  //   try {
  //     const responseSession = await get<SessionTokenResponse>(`${validatorUrl}/session-token`, {}, {
  //       'X-Device-Key': deviceKeyIdentifier,
  //       'X-User-Agent': createUserAgent(''),
  //     });

  //     ({ sessionToken } = responseSession);

  //     if (!sessionToken) {
  //       console.error(responseSession);
  //       throw Error('initialization-error');
  //     }
  //   } catch (e) {
  //     console.error(e);
  //     throw Error('initialization-error');
  //   }

  //   try {
  //     const initialized = await initialize({
  //       deviceKeyIdentifier,
  //       publicFaceScanEncryptionKey,
  //       // productionKey,
  //     });

  //     if (!initialized) {
  //       console.warn('initialization-result:', initialized);
  //       throw Error('initialization-error');
  //     }
  //   } catch (e) {
  //     console.error(e);
  //     throw Error('initialization-error');
  //   }

  //   Distortion.configureLocalization(translation[locale]);

  //   try {
  //     Distortion.checkLiveness3D({
  //       sessionToken,
  //       onComplete: () => console.log('onComplete'),
  //       onError: (error: string) => console.error('onError:', error),
  //       onResult: async (sessionResult: DistortionSessionResult) => {
  //         const responseLiveness = await post<CheckLivenessResponse>(`${validatorUrl}/liveness-3d`, {
  //           faceScan: sessionResult.faceScan,
  //           auditTrailImage: sessionResult.auditTrail[0],
  //           lowQualityAuditTrailImage: sessionResult.lowQualityAuditTrail[0],
  //         }, {
  //           'X-Device-Key': deviceKeyIdentifier,
  //           'X-User-Agent': createUserAgent(sessionResult.sessionId as string),
  //         });

  //         if (!responseLiveness || !responseLiveness.wasProcessed || !responseLiveness.scanResultBlob) {
  //           console.error(responseLiveness);
  //           throw Error('check-liveness-error');
  //         }

  //         return { status: 'success', scanResultBlob: responseLiveness.scanResultBlob };
  //       },
  //     });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, [deviceKeyIdentifier, locale, publicFaceScanEncryptionKey, translation, initialize]);

  return (
    <TestView
      connectWallet={connectWallet}
      setAirdropUnikaAddressToMedium={setAirdropUnikaAddressToMedium}
      setAirdropUnikaAddressToRelay={setAirdropUnikaAddressToRelay}
      getIdentifications={getIdentifications}
      getIdentificationTimestamp={getIdentificationTimestamp}
      getUNodeWhiteList={getUNodeWhiteList}
      getAirdropWhitelistAddresses={getAirdropWhitelistAddresses}
      getInteractiveStageRequests={getInteractiveStageRequests}
      getVerificationRequests={getVerificationRequests}
      getIdentificationActiveRequests={getIdentificationActiveRequests}
      getVerificationActiveRequests={getVerificationActiveRequests}
      initializeIdentification={initializeIdentificationRequest}
      initializeIdentificationRelay={initializeIdentificationRequestRelay}
      transmitIdentificationRequest={transmitIdentificationRequest}
      initializeClaimRequest={initializeClaimRequest}
      initializeVerification={initializeVerificationRequest}
      initializeVerificationRelay={initializeVerificationRequestRelay}
      transmitVerificationRequest={transmitVerificationRequest}
      claimInteractiveStageRequest={claimInteractiveStageRequest}
      claimVerificationRequest={claimVerificationRequest}
      finalizeInteractiveStageRequest={finalizeInteractiveStageRequest}
      finalizeVerificationRequest={finalizeVerificationRequest}
      transmitVerificationResult={transmitVerificationResult}
      checkInteractiveStageRequests={checkInteractiveStageRequests}
      checkVerificationRequests={checkVerificationRequests}
      checkIdentificationActiveRequests={checkIdentificationActiveRequests}
      checkVerificationActiveRequests={checkVerificationActiveRequests}
      checkIfAddressExists={checkIfAddressExists}
      // startIdentification={startIdentification}
    />
  );
});
