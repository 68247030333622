import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type ConnectWalletProps = {
  size?: number;
};

export const ConnectWallet: FC<ConnectWalletProps & ClassNameProps> = memo(({
  size = 171,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size / 4.75} viewBox="0 0 171 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.67992 1H2C1.44772 1 1 1.44772 1 2V6.672M163.35 1H168.001C168.554 1 169.001 1.44772 169.001 2V6.66736M169.001 29.3201V34.0013C169.001 34.5536 168.554 35.0013 168.001 35.0013H163.34M6.67931 35.0013H2C1.44772 35.0013 1 34.5536 1 34.0013V29.3262" stroke="#A2C0CD" strokeWidth="2" strokeLinecap="round" />
  </svg>
));
