import { FC, memo } from 'react';
import { Theme, theme } from '../../theme';
import { ClassNameProps, ThemeColor, ThemeColorProvider } from '@spatium/wallet-kit';

export type ButtonMobileProps = {
  size?: number;
  themeColor?: ThemeColor<Theme>;
};

export const ButtonMobile: FC<ButtonMobileProps & ClassNameProps> = memo(({
  size = 343,
  themeColor = theme.colors.foreground,
  className,
  style,
}) => (
  <ThemeColorProvider themeColor={themeColor}>
    {(color) => (
      <svg className={className} style={style} width={size} height={60} viewBox="0 0 343 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_biiii_1226_6335)">
          <path d="M0.5 12.5748V41.6964C0.5 43.2962 1.13889 44.8298 2.27473 45.9564L4.09497 47.7618C5.21875 48.8764 6.73744 49.5018 8.32024 49.5018H25.0168C27.0043 49.5018 28.8628 50.486 29.9797 52.13L33.5411 57.3718C34.658 59.0158 36.5165 60 38.504 60H329.997C331.174 60 332.325 59.6538 333.306 59.0045L339.81 54.7034C341.49 53.5924 342.5 51.7128 342.5 49.6989V23.8284V20.1202C342.5 18.5611 341.893 17.0632 340.808 15.9438L336.837 11.848C335.707 10.6824 334.153 10.0244 332.529 10.0244H318.409C316.381 10.0244 314.491 9.00045 313.383 7.3022L310.396 2.72223C309.288 1.02398 307.398 0 305.371 0H13.3222C11.7692 0 10.2767 0.602124 9.15855 1.67977L2.33639 8.25461C1.16294 9.38553 0.5 10.9451 0.5 12.5748Z" fill="url(#paint0_linear_1226_6335)" />
        </g>
        <defs>
          <filter id="filter0_biiii_1226_6335" x="-99.5" y="-100" width="542" height="260" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="50" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1226_6335" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1226_6335" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="9" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1226_6335" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="98" />
            <feGaussianBlur stdDeviation="50" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="effect2_innerShadow_1226_6335" result="effect3_innerShadow_1226_6335" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="-82" />
            <feGaussianBlur stdDeviation="34" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="effect3_innerShadow_1226_6335" result="effect4_innerShadow_1226_6335" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="7" />
            <feGaussianBlur stdDeviation="5.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="effect4_innerShadow_1226_6335" result="effect5_innerShadow_1226_6335" />
          </filter>
          <linearGradient id="paint0_linear_1226_6335" x1="354.43" y1="-66.6667" x2="106.763" y2="203.02" gradientUnits="userSpaceOnUse">
            <stop stopColor="#05D2FF" />
            <stop offset="0.412877" stopColor="#2BF5FF" />
            <stop offset="0.819136" stopColor="#04F8CA" />
          </linearGradient>
        </defs>
      </svg>

    )}
  </ThemeColorProvider>
));
