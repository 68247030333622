import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type CoinbaseProps = {
  size?: number;
};

export const Coinbase: FC<CoinbaseProps & ClassNameProps> = memo(({
  size = 40,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2041_7729)">
      <path d="M60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30Z" fill="#0052FF" />
      <path d="M30.0088 40.5469C24.1817 40.5469 19.4619 35.8271 19.4619 30C19.4619 24.1729 24.1817 19.4531 30.0088 19.4531C35.2295 19.4531 39.5626 23.2588 40.3975 28.2422H51.0235C50.127 17.4141 41.0654 8.90625 30.0088 8.90625C18.3633 8.90625 8.91504 18.3545 8.91504 30C8.91504 41.6455 18.3633 51.0938 30.0088 51.0938C41.0654 51.0938 50.127 42.5859 51.0235 31.7578H40.3887C39.5538 36.7412 35.2295 40.5469 30.0088 40.5469Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2041_7729">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
));
