import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type DropdownArrowProps = {
  size?: number;
};

export const DropdownArrow: FC<DropdownArrowProps & ClassNameProps> = memo(({
  size = 18,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.09 6.96902L9 9.87902L11.91 6.96902C11.9794 6.89958 12.0619 6.8445 12.1526 6.80692C12.2433 6.76934 12.3405 6.75 12.4387 6.75C12.5369 6.75 12.6342 6.76934 12.7249 6.80692C12.8156 6.8445 12.8981 6.89958 12.9675 6.96902C13.0369 7.03845 13.092 7.12089 13.1296 7.21161C13.1672 7.30233 13.1865 7.39957 13.1865 7.49777C13.1865 7.59596 13.1672 7.6932 13.1296 7.78392C13.092 7.87465 13.0369 7.95708 12.9675 8.02652L9.525 11.469C9.45561 11.5385 9.3732 11.5937 9.28247 11.6313C9.19174 11.669 9.09447 11.6883 8.99625 11.6883C8.89802 11.6883 8.80076 11.669 8.71003 11.6313C8.6193 11.5937 8.53688 11.5385 8.4675 11.469L5.025 8.02652C4.95547 7.95713 4.90031 7.87471 4.86267 7.78398C4.82504 7.69325 4.80566 7.59599 4.80566 7.49777C4.80566 7.39954 4.82504 7.30228 4.86267 7.21155C4.90031 7.12082 4.95547 7.0384 5.025 6.96902C5.3175 6.68402 5.7975 6.67652 6.09 6.96902Z" fill="#233045" />
  </svg>
));
