import styled from 'styled-components';
import { Button, Text, theme } from 'theme';

export const CornerBorderButtonBase = styled(Button)`
    position: relative;
    cursor: pointer;

    ${Text} {
        transition: all 0.3s linear;
    }

    svg {
        path {
            transition: all 0.3s linear;
        }
    }
    
    &:hover:enabled {
        ${Text} {
            color: ${theme.colors.primary};
        }

        svg {
            path {
                stroke: ${theme.colors.primary};
            }
        }
    }
`;

export const ConnectText = styled.div`
    width: 100%;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    letter-spacing: 0.05em;
`;
