import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type VerifiedDottedLineProps = {
  size?: number;
};

export const VerifiedDottedLine: FC<VerifiedDottedLineProps & ClassNameProps> = memo(({
  size = 647,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size / 161.75} viewBox="0 0 647 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0.501546" y1="1.00155" x2="645.502" y2="2.99845" stroke="#A2C0CD" strokeLinecap="round" strokeDasharray="8 8" />
  </svg>
));
