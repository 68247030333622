import { FC, memo, ReactNode } from 'react';
import { Background, HeaderRow, theme, ThemeTRBL, View, ThemeSpace, Column } from 'theme';
import { ClassNameProps } from '@spatium/wallet-kit';

export type HeadedViewProps = {
  unbound?: boolean;
  background?: ReactNode;
  themePadding?: ThemeTRBL;
  headerBackground?: ReactNode;
  themeHeaderInset?: ThemeSpace;
  footerBackground?: ReactNode;
  themeFooterInset?: ThemeSpace;
  header?: ReactNode;
  footer?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  top?: ReactNode;
  controlLevel?: number;
  onClick?: () => void;
} & ClassNameProps;

export const HeadedView: FC<HeadedViewProps> = memo(({
  unbound = false,
  background,
  themeHeaderInset,
  themeFooterInset,
  header,
  footer,
  themePadding,
  left,
  right,
  top,
  children,
  headerBackground,
  footerBackground,
  controlLevel = 2,
  onClick,
  className,
  style,
}) => (
  <View
    unbound={unbound}
    controlLevel={controlLevel}
    themeHeaderInset={themeHeaderInset}
    themeFooterInset={themeFooterInset}
    headerBackground={headerBackground}
    header={(
      <Column horizontalAlign="stretch">
        <HeaderRow
          themePadding={themePadding ?? [theme.space(0), theme.spaces.side, theme.space(0), theme.space(0)]}
          themeHeight={theme.space(70)}
          right={right}
          left={left}
        >
          {top}
        </HeaderRow>
        {header}
      </Column>
    )}
    footerBackground={footerBackground}
    footer={footer}
    background={background || <Background themeColor={theme.colors.background} />}
    onClick={onClick}
    className={className}
    style={style}
  >
    {children}
  </View>
));
