import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type WalletConnectProps = {
  size?: number;
};

export const WalletConnect: FC<WalletConnectProps & ClassNameProps> = memo(({
  size = 20,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 96 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6647 12.3345C35.3154 -2.77816 60.6846 -2.77816 76.3556 12.3345L78.2385 14.1512C79.0281 14.9098 79.0281 16.1276 78.2385 16.8862L71.8001 23.115C71.4154 23.4943 70.7675 23.4943 70.3828 23.115L67.7912 20.6195C56.8782 10.0786 39.1623 10.0786 28.2493 20.6195L25.4755 23.2946C25.0908 23.674 24.4429 23.674 24.0582 23.2946L17.5995 17.0659C16.8099 16.3073 16.8099 15.0895 17.5995 14.3309L19.6647 12.3345ZM89.6779 25.1912L95.4078 30.7212C96.1974 31.4798 96.1974 32.6976 95.4078 33.4562L69.5527 58.431C68.763 59.1897 67.5077 59.1897 66.7181 58.431L48.3543 40.7031C48.1518 40.5234 47.8481 40.5234 47.6457 40.7031L29.3021 58.431C28.5125 59.1897 27.2572 59.1897 26.4676 58.431L0.592218 33.4762C-0.197406 32.7176 -0.197406 31.4998 0.592218 30.7412L6.32205 25.2112C7.11167 24.4525 8.36697 24.4525 9.1566 25.2112L27.5204 42.9391C27.7229 43.1188 28.0266 43.1188 28.229 42.9391L46.5726 25.2112C47.3622 24.4525 48.6175 24.4525 49.4071 25.2112L67.771 42.9391C67.9734 43.1188 68.2771 43.1188 68.4796 42.9391L86.8434 25.2112C87.6128 24.4326 88.8883 24.4326 89.6779 25.1912Z" fill="#3A99FB" />
  </svg>
));
