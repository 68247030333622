import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type VerifiedCheckedIconProps = {
  size?: number;
};

export const VerifiedCheckedIcon: FC<VerifiedCheckedIconProps & ClassNameProps> = memo(({
  size = 65,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32.5" r="21" fill="black" />
    <path d="M28.2663 44.7654L47.0663 25.9654L43.333 22.232L28.2663 37.2987L20.6663 29.6987L16.933 33.432L28.2663 44.7654ZM31.9997 59.1654C28.3108 59.1654 24.8441 58.4649 21.5997 57.064C18.3552 55.6649 15.533 53.7654 13.133 51.3654C10.733 48.9654 8.83345 46.1431 7.43434 42.8987C6.03345 39.6543 5.33301 36.1876 5.33301 32.4987C5.33301 28.8098 6.03345 25.3431 7.43434 22.0987C8.83345 18.8543 10.733 16.032 13.133 13.632C15.533 11.232 18.3552 9.33159 21.5997 7.9307C24.8441 6.53159 28.3108 5.83203 31.9997 5.83203C35.6886 5.83203 39.1552 6.53159 42.3997 7.9307C45.6441 9.33159 48.4663 11.232 50.8663 13.632C53.2663 16.032 55.1659 18.8543 56.565 22.0987C57.9659 25.3431 58.6663 28.8098 58.6663 32.4987C58.6663 36.1876 57.9659 39.6543 56.565 42.8987C55.1659 46.1431 53.2663 48.9654 50.8663 51.3654C48.4663 53.7654 45.6441 55.6649 42.3997 57.064C39.1552 58.4649 35.6886 59.1654 31.9997 59.1654Z" fill="#04F8CA" />
  </svg>
));
